<template>
  <vx-card
    v-show="ready"
    :title="cardTitle"
    title-color="rgba(241,56,77)"
    card-background="white"
    class="overflow-hidden main-card w-full"
    headerBackground="rgba(241,56,77)"
    :collapse-action="false"
    :remove-card-action="false"
    :refresh-content-action="false"
    :fixed-height="true"
  >
    <template slot="no-body" style="width: 100%">
      <div class="chat-card-log pt-4">
        <VuePerfectScrollbar
          id="chatLogPS"
          ref="chatLogPS"
          class="scroll-area pt-6 px-6"
        >
          <ul ref="chatLog">
            <div v-for="(message, index1) in messages" :key="`${index1}vfor1`">
              <div v-if="message.type === 'user'">
                <li
                  v-if="
                    message.input &&
                      message.input.length > 0 &&
                      message.input[0] !== DO_NOT_SHOW_THIS
                  "
                  class="flex items-start"
                  :class="{ 'flex-row-reverse': true, 'mt-4': index1 }"
                >
                  <avatar
                    :size="40"
                    :class="'ml-5 m-0 flex-shrink-0'"
                    :username="session.name"
                    :lighten="100"
                    class="shadow-md cursor-pointer avatar"
                  />
                  <div
                    class="
                      msg
                      relative
                      bg-white
                      shadow-md
                      py-3
                      px-4
                      mb-2
                      rounded-lg
                      max-w-md
                    "
                    :class="{
                      'chat-sent-msg bg-primary-gradient text-white': true
                    }"
                  >
                    <span>{{ message.input[0] }}</span>
                  </div>
                </li>
                <div
                  v-for="(response, index) in message.output"
                  :key="`${index}vfor2`"
                >
                  <li
                    class="flex items-start"
                    :class="{
                      'flex-row-reverse': false,
                      'mt-4': index1 != 0
                    }"
                    v-if="
                      response.responseText &&
                        response.responseText.trim().length > 0
                    "
                  >
                    <avatar
                      :size="40"
                      :class="'mr-5'"
                      :username="name"
                      :lighten="100"
                      class="shadow-md cursor-pointer avatar"
                    />
                    <div
                      class="
                        msg
                        relative
                        bg-white
                        shadow-md
                        py-3
                        px-4
                        mb-2
                        rounded-lg
                        max-w-md
                      "
                      :class="{ 'bg-warning-gradient text-white': true }"
                    >
                      <span v-dompurify-html="textToHTML(response.responseText)"></span>
                    </div>
                  </li>
                  <li
                    v-if="
                      response.responseFile &&
                        response.responseFile.type &&
                        response.responseFile.url
                    "
                    class="flex items-start"
                    :class="{
                      'flex-row-reverse': false,
                      'mt-4': index1 != 0
                    }"
                  >
                    <avatar
                      :size="40"
                      :class="'mr-5'"
                      :username="name"
                      :lighten="100"
                      class="shadow-md cursor-pointer avatar"
                    />
                    <div
                      class="
                        msg
                        relative
                        bg-white
                        shadow-md
                        py-3
                        px-4
                        mb-2
                        rounded-lg
                        max-w-md
                      "
                      :class="{ 'bg-warning-gradient text-white': true }"
                    >
                      <img
                        :src="response.responseFile.url"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </li>
                  <div
                    class="flex items-start"
                    v-if="
                      response.responseOptions &&
                        response.responseOptions.length > 0
                    "
                    style="margin-left: 55px"
                  >
                    <div
                      v-for="(option, index) in response.responseOptions"
                      :key="`${index}vfor3`"
                      class="items-start"
                      @click="
                        send({
                          text: option.text,
                          skip: sending || index1 !== messages.length - 1
                        })
                      "
                    >
                      <vs-chip
                        class="mt-4 no-select pt-2 pb-2"
                        :color="
                          sending || index1 !== messages.length - 1
                            ? ''
                            : 'warning'
                        "
                        :style="{
                          cursor:
                            sending || index1 !== messages.length - 1
                              ? ''
                              : 'pointer',
                          'font-size': '14px'
                        }"
                      >
                        {{ option.text }}
                      </vs-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <li
                  class="flex items-start"
                  :class="{ 'flex-row-reverse': false, 'mt-4': index1 != 0 }"
                >
                  <avatar
                    :size="40"
                    :class="'mr-5'"
                    :username="name"
                    :lighten="100"
                    class="shadow-md cursor-pointer avatar"
                  />
                  <div
                    class="
                      msg
                      relative
                      bg-white
                      shadow-md
                      py-3
                      px-4
                      mb-2
                      rounded-lg
                      max-w-md
                    "
                    :class="{ 'bg-warning-gradient text-white': true }"
                  >
                    <span>{{ message.input[0] }}</span>
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </VuePerfectScrollbar>
      </div>
      <div class="flex chat-input-container p-6">
        <vs-input
          color="rgba(241,56,77)"
          v-focus
          ref="input"
          class="mr-3 w-full"
          v-model="chatMsgInput"
          @keyup.enter="send"
          :placeholder="inputPlaceholder"
          :disabled="sending"
        ></vs-input>
        <vs-button
          color="rgba(241,56,77)"
          radius
          type="filled"
          icon-pack="feather"
          icon="icon-send"
          @click="send"
          :disabled="
            !chatMsgInput || chatMsgInput.trim().length === 0 || sending
          "
        ></vs-button>
      </div>
    </template>
  </vx-card>
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import state from '@/store/state'

import { uuid } from 'vue-uuid'

import { mapState } from 'vuex'

const DO_NOT_SHOW_THIS = 'DO_NOT_SHOW_THIS'

export default {
  name: 'SurveyTest',
  props: {
    name: {
      type: String
    }
  },
  components: {
    VuePerfectScrollbar,
    Avatar: () => import('vue-avatar')
  },
  computed: {
    ...mapState(['session'])
  },
  data() {
    return {
      ready: true,

      backend: state.config.konecta,
      uuid: uuid.v1(),

      DO_NOT_SHOW_THIS,
      sending: true,

      context: {},
      messages: [],
      chatMsgInput: '',

      cardTitle: '',
      inputPlaceholder: 'Escribe un mensaje...'
    }
  },
  created() {
    this.cardTitle = '💬 ' + (this.$route.query.name || 'chat')
    this.$socket.client.emit('join', {
      type: 'sender',
      bot: this.$route.query.survey,
      version: this.$route.query.version,
      sender: this.uuid
    })
    if (this.$route.query.lang) {
      switch (this.$route.query.lang) {
        case 'en':
          this.inputPlaceholder = 'Type your message...'
          break
        default:
          break
      }
    }
    this.$socket.client.on('userMessage', data => {
      this.messages.push(data.message)
      this.scrollToEnd()
      this.sending = false
    })
    const body = {
      sender: this.uuid,
      entry: [
        {
          messaging: [
            {
              message: {
                text: 'DO_NOT_SHOW_THIS'
              }
            }
          ]
        }
      ],
      context: {},
      events: [
        {
          key: 'start survey'
        }
      ]
    }
    const url = this.$route.query.version
      ? `${this.backend}/conversation/survey-test/${this.$route.query.survey}?version=${this.$route.query.version}`
      : `${this.backend}/conversation/survey-test/${this.$route.query.survey}`
    axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.context = response.data.context
        this.ready = true
      })
      .catch(response => {
        console.error(response)
      })
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  },
  methods: {
    textToHTML: text => {
      let aux = text
      const links = aux.match(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
      )
      ;(links || []).forEach(link => {
        aux = aux.replace(link, `<a href="${link}" target="_blank">${link}</a>`)
      })
      aux = aux.replace(/\n/g, '<br>')
      return aux
    },
    send: function(params) {
      if (params && params.skip) {
        return
      }
      if (!params || (!params.text && this.chatMsgInput.trim().length === 0)) {
        return
      }
      this.sending = true
      const body = {
        sender: this.uuid,
        entry: [
          {
            messaging: [
              {
                message: {
                  text:
                    params && params.text
                      ? params.text
                      : this.chatMsgInput.trim()
                }
              }
            ]
          }
        ],
        context: this.context
      }
      const url = this.$route.query.version
        ? `${this.backend}/conversation/survey-test/${this.$route.query.survey}?version=${this.$route.query.version}`
        : `${this.backend}/conversation/survey-test/${this.$route.query.survey}`
      axios
        .post(url, body, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.context = response.data.context
          this.sending = false
          this.chatMsgInput = ''
        })
        .catch(response => {
          this.sending = false
          console.error(response)
        })
    },
    scrollToEnd() {
      setTimeout(() => {
        const container = this.$el.querySelector('.ps-container')
        container.scrollTop = container.scrollHeight
        this.$refs.input.$el.querySelector('input').focus()
      }, 0)
    }
  }
}
</script>

<style lang="scss">
.main-card {
  display: flex;
  flex-direction: column;
  // flex-shrink: 0;
  flex: 1;
  height: 100%;
  width: 100vw;
}
.chat-card-log {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  .chat-sent-msg {
    background-color: #f2f4f7 !important;
  }
}

.vx-card.card-border {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.chat-input-container {
  flex: 0 0 auto;
}
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
