<template>
  <div class="survey-editor">
    <div v-if="survey && survey.length > 0">

      <div class="relative">
        <div class="absolute tabs-actions">
          <vs-button
           v-if="isAuthAux('konecta.surveys.edit', true)"
            icon="icon-plus"
            icon-pack="feather"
            @click="openNewItemPopUp(0, true)"
            v-tooltip="lang.surveyMaker.groups.addGroup[languageSelected]"
            radius
          />
        </div>

        <KonaTabs
          :key="konaTabsKey"
          v-if="surveyGroupOrder.length"
          :returnSelectedTab="true"
          @selectedTab="onGroupChange"
          :tabsStyle="tabsStyle"
        >
          <KonaTab
            v-for="(surveyGroup, sgIndex) in surveyGroupOrder"
            :key="sgIndex"
            :name="surveyGroup.group || `${sgIndex + 1}`"
            :selected="sgIndex === 0"
          >
            <draggable
              :list="surveyGroup.model"
              :class="{ 'cursor-move': isAuthAux('konecta.surveys.edit', true) }"
              :move="checkMove"
              @end="dragMessage"
            >
              <div
                ref="survey"
                v-for="(item, index) in surveyGroup.model"
                :key="index + aux"
                class="mb-2 survey-question"
              >
                <!-- question actions first item -->
                <div
                  class="flex justify-end mb-2"
                  v-if="!item.finish && index === 0"
                >
                  <!-- new question button -->
                  <vs-button
                    v-if="isAuthAux('konecta.surveys.edit', true)"
                    class="mr-2"
                    size="small"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-plus"
                    radius
                    @click="openNewItemPopUp(index)"
                  />

                  <!-- new message button -->
                  <vs-button
                    v-if="isAuthAux('konecta.surveys.edit', true)"
                    class="text-dark"
                    size="small"
                    color="#f0f0f0"
                    icon-pack="feather"
                    icon="icon-message-circle"
                    radius
                    @click="openNewMessagePopUp(index)"
                  />
                </div>

                <!-- message -->
                <vx-card v-if="item.isMessage">
                  <vs-row>
                    <vs-col vs-justify="center" vs-align="center" vs-w="12">
                      <feather-icon
                        v-if="isAuthAux('konecta.surveys.edit', true)"
                        icon="Trash2Icon"
                        svgClasses="w-5 h-5"
                        style="cursor: pointer"
                        class="float-right text-danger"
                        @click="removeNode(item)"
                      />
                      <div class="float-right">&nbsp;</div>
                      <feather-icon
                        v-if="isAuthAux('konecta.surveys.edit', true)"
                        icon="EditIcon"
                        svgClasses="w-5 h-5"
                        style="cursor: pointer"
                        class="float-right"
                        @click="toggleEditMessage(item)"
                      />

                      <h5 class="mb-6">
                        ({{ index + 1 }})
                        <span v-if="item.name">{{ item.name }}</span>
                        <span v-else>
                          {{
                            lang.surveyMaker.editor.message[languageSelected]
                          }}
                        </span>
                      </h5>
                      <!-- <p class="mb-2">{{ item.question }}</p> -->
                      <text-highlight :queries="/{{(.*?)}}/g">
                        {{ item.question }}
                      </text-highlight>
                    </vs-col>
                  </vs-row>
                </vx-card>

                <!-- question -->
                <vx-card v-else-if="!item.finish">
                  <vs-row>
                    <!-- NODO -->
                    <vs-col vs-justify="center" vs-align="center" vs-w="6">

                      <h5 class="mb-6">({{ index + 1 }}) {{ item.name }}</h5>

                      <feather-icon
                        icon="HelpCircleIcon"
                        svgClasses="w-6 h-6"
                      />
                      <p class="mb-2">
                        <b>
                          {{
                            lang.surveyMaker.editor.question[languageSelected]
                          }}
                        </b>
                      </p>
                      <!-- <p class="mb-2">{{ item.question }}</p> -->
                      <p class="mb-2">
                        <text-highlight :queries="/{{(.*?)}}/g">
                          {{ item.question }}
                        </text-highlight>
                      </p>
                      <div v-if="item.requestion">
                        <feather-icon
                          icon="HelpCircleIcon"
                          svgClasses="w-6 h-6"
                        />
                        <p>
                          <b>
                            {{
                              lang.surveyMaker.editor.requestion[
                                languageSelected
                              ]
                            }}
                          </b>
                        </p>
                        <p class="mb-2">{{ item.requestion }}</p>
                      </div>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-w="6">
                      <feather-icon
                        v-if="isAuthAux('konecta.surveys.edit', true)"
                        icon="Trash2Icon"
                        svgClasses="w-5 h-5"
                        style="cursor: pointer"
                        class="float-right text-danger"
                        @click="removeNode(item)"
                      />
                      <div class="float-right">&nbsp;</div>
                      <feather-icon
                        v-if="isAuthAux('konecta.surveys.edit', true)"
                        icon="EditIcon"
                        svgClasses="w-5 h-5"
                        style="cursor: pointer"
                        class="float-right"
                        @click="toggleEdit(item)"
                      />

                      <feather-icon
                        v-if="item.responseType === 1"
                        icon="MessageCircleIcon"
                        svgClasses="w-6 h-6"
                      />
                      <feather-icon
                        v-if="item.responseType === 2"
                        icon="HashIcon"
                        svgClasses="w-6 h-6"
                      />
                      <feather-icon
                        v-if="item.responseType === 3"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6"
                      />
                      <p>
                        <b>{{
                          lang.surveyMaker.editor.answer[languageSelected]
                        }}</b>
                      </p>
                      <p class="mb-0">
                        {{ responseTypes[item.responseType - 1].text }}
                      </p>
                      <p v-if="item.clusterizeAnswers" class="mb-0">
                        {{ lang.surveyMaker.withClustering[languageSelected] }}
                      </p>
                      <i
                        v-if="item.clusterizeAnswers"
                        class="material-icons text-primary"
                        color="primary"
                        >group_work</i
                      >
                      <div v-if="item.responseType === 3">
                        <vs-chip
                          class="mr-2 mt-2 mb-2"
                          color="primary"
                          v-for="(option, index) in item.options"
                          :key="index"
                          >{{ option }}</vs-chip
                        >
                        <vs-divider class="pt-2" />
                      </div>
                      <vs-divider v-else />
                      <feather-icon icon="SaveIcon" svgClasses="w-6 h-6" />
                      <p>
                        <b>
                          {{ lang.surveyMaker.editor.saveAs[languageSelected] }}
                        </b>
                      </p>
                      <p class="mb-2">{{ item.key }}</p>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-w="12">
                      <vs-divider
                        position="left"
                        v-if="item.conditions && item.conditions.length > 0"
                      >
                        <div class="flex items-center">
                          <span>
                            {{
                              lang.surveyMaker.conditions.title[
                                languageSelected
                              ]
                            }}
                          </span>
                          <KonaHelpTooltip
                            :text="
                              lang.surveyMaker.conditions.tooltip[
                                languageSelected
                              ]
                            "
                            position="right"
                          />
                        </div>
                      </vs-divider>

                      <!-- condition -->
                      <div
                        v-for="(condition, conditionIndex) in item.conditions"
                        :key="conditionIndex"
                        class="condition mb-4"
                      >
                        <span class="material-icons-outlined mr-2">
                          check_circle_outline
                        </span>
                        <span>
                          {{
                            lang.surveyMaker.conditions.conditionText.if[
                              languageSelected
                            ]
                          }}
                          <strong class="ml-1 mr-1" v-if="condition.func">
                            {{
                              lang.surveyMaker.conditions.texts[condition.func][
                                languageSelected
                              ]
                            }}
                          </strong>
                          {{ condition.args[0] }}
                          <span class="mr-1">
                            {{
                              lang.surveyMaker.conditions.conditionText.then[
                                languageSelected
                              ]
                            }}
                          </span>
                          <strong>
                            {{ condition.jump.group }}
                          </strong>
                        </span>
                      </div>
                    </vs-col>
                  </vs-row>
                </vx-card>

                <!-- final message -->
                <div v-else style="cursor: default">
                  <vx-card>
                    <vs-row>
                      <vs-col vs-justify="center" vs-align="center" vs-w="6">
                        <h5 class="mb-6">
                          ({{ index + 1 }})
                          {{
                            lang.surveyMaker.editor.finalMessage[
                              languageSelected
                            ]
                          }}
                        </h5>
                        <!-- <p class="mb-2">{{ item.question }}</p> -->
                        <text-highlight :queries="/{{(.*?)}}/g">
                          {{ item.question }}
                        </text-highlight>
                      </vs-col>
                      <vs-col vs-justify="center" vs-align="center" vs-w="6">
                        <feather-icon
                          v-if="isAuthAux('konecta.surveys.edit', true)"
                          icon="EditIcon"
                          svgClasses="w-5 h-5"
                          style="cursor: pointer"
                          class="float-right"
                          @click="toogleEditFinalMessage"
                        />
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>

                <!-- question actions -->
                <div class="flex justify-end mt-2" v-if="!item.finish">
                  <!-- new question button -->
                  <vs-button
                    v-if="isAuthAux('konecta.surveys.edit', true)"
                    class="mr-2"
                    size="small"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-plus"
                    radius
                    @click="openNewItemPopUp(index + 1)"
                  />

                  <!-- new message button -->
                  <vs-button
                    v-if="isAuthAux('konecta.surveys.edit', true) && index !== Object.keys(surveyGroup.model).length - 1"
                    class="text-dark"
                    size="small"
                    color="#f0f0f0"
                    icon-pack="feather"
                    icon="icon-message-circle"
                    radius
                    @click="openNewMessagePopUp(index + 1)"
                  />
                </div>
              </div>
            </draggable>
            <div style="height: 45px"></div>
            <div class="mb-4"></div>
          </KonaTab>
        </KonaTabs>
      </div>
    </div>

    <!-- new question popup -->
    <vs-popup
      :title="
        newGroup
          ? lang.surveyMaker.groups.addGroup[languageSelected]
          : lang.surveyMaker.editor.newItem.title[languageSelected]
      "
      :active.sync="newItem.popup"
      v-if="newItem.popup"
    >

      <form-wizard
        ref="newItemFormWizard"
        color="rgba(var(--vs-primary), 1)"
        title=""
        subtitle=""
        :nextButtonText="
          lang.surveyMaker.editor.newItem.nextButtonText[languageSelected]
        "
        :backButtonText="
          lang.surveyMaker.editor.newItem.backButtonText[languageSelected]
        "
        :finishButtonText="
          lang.surveyMaker.editor.newItem.finishButtonText[languageSelected]
        "
        @on-complete="addNode"
      >
        <!-- new group name -->
        <tab-content
          v-if="newGroup"
          :title="lang.surveyMaker.groups.addGroup[languageSelected]"
          class="mb-5"
          icon="feather icon-folder-plus"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <div class="flex items-center" v-if="newGroup">
                <vs-input label="Group name *" v-model="newItem.group" />
                <KonaHelpTooltip
                  class="mt-5 ml-2"
                  :text="lang.surveyMaker.groups.tooltip[languageSelected]"
                  position="right"
                />
              </div>
            </vs-col>
          </div>
        </tab-content>

        <!-- question -->
        <tab-content
          :title="lang.surveyMaker.editor.newItem.tab1.title[languageSelected]"
          class="mb-5"
          icon="feather icon-help-circle"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                :label="
                  lang.surveyMaker.editor.newItem.tab1.name[languageSelected]
                "
                class="mb-4 w-full"
                v-model="newItem.name"
              />
              <vs-textarea
                v-model="newItem.question"
                :label="
                  lang.surveyMaker.editor.newItem.tab1.question[
                    languageSelected
                  ]
                "
              />
              <vs-textarea
                v-model="newItem.requestion"
                :label="
                  lang.surveyMaker.editor.newItem.tab1.requestion[
                    languageSelected
                  ]
                "
              />
            </vs-col>
          </div>
        </tab-content>

        <!-- response -->
        <tab-content
          :title="lang.surveyMaker.editor.newItem.tab2.title[languageSelected]"
          class="mb-5"
          icon="feather icon-target"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <ul class>
                <li
                  v-for="(item, index) in lang.surveyMaker.editor.newItem.tab2
                    .responseTypes[languageSelected]"
                  :key="index"
                >
                  <vs-radio
                    v-model="newItem.responseType"
                    :vs-value="item.value"
                  >
                    {{ item.text }}
                  </vs-radio>
                </li>
              </ul>
            </vs-col>
          </div>
          <div class="vx-row" v-if="newItem.responseType === 1">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-divider />
              <label for>
                {{ lang.surveyMaker.clusterizeAnswers[languageSelected] }}
              </label>
              <vx-slider
                v-model="newItem.clusterizeAnswers"
                vs-icon-on="group_work"
                class="new-question__clusterize-answer"
              />
            </vs-col>
          </div>
          <div class="vx-row" v-if="newItem.responseType === 3">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-divider />
              <vs-input
                :label="
                  lang.surveyMaker.editor.newItem.tab2.options.label[
                    languageSelected
                  ]
                "
                v-on:keyup.enter="addOption"
                :placeholder="
                  lang.surveyMaker.editor.newItem.tab2.options.placeholder[
                    languageSelected
                  ]
                "
                class="mb-2 w-full"
                v-model="newItem.optionsInput"
              />
              <draggable :list="newItem.options" :class="{ 'cursor-move': isAuthAux('konecta.surveys.edit', true) }">
                <vs-chip
                  @click="removeOption(option)"
                  class="mr-2 mt-3"
                  color="primary"
                  v-for="(option, index) in newItem.options"
                  :key="index"
                  closable
                >
                  {{ option }}
                </vs-chip>
              </draggable>
            </vs-col>
          </div>
        </tab-content>

        <!-- logic -->
        <tab-content
          :title="lang.surveyMaker.editor.newItem.tab3.title[languageSelected]"
          class="mb-5"
          icon="feather icon-settings"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <p
                class="flex justify-center mt-4 mb-4"
                v-if="newItem.conditions.length === 0"
              >
                {{ lang.surveyMaker.conditions.empty[languageSelected] }}
              </p>
              <!-- condition -->
              <div
                v-for="(condition, conditionIndex) in newItem.conditions"
                :key="conditionIndex"
                class="condition mb-4"
              >
                <!-- func -->
                <div class="mr-4 vs-component vs-con-input-label">
                  <label for="" class="vs-input--label">{{
                    lang.surveyMaker.conditions.form.function[languageSelected]
                  }}</label>
                  <multiselect
                    :allow-empty="false"
                    v-model="condition.func"
                    :showLabels="false"
                    :placeholder="
                      lang.surveyMaker.conditions.form.function[
                        languageSelected
                      ]
                    "
                    :custom-label="
                      opt => SURVEY_CONDITIONS.find(c => c.value == opt).text
                    "
                    :options="SURVEY_CONDITIONS_KEYS"
                  />
                </div>

                <!-- args -->
                <vs-input
                  class="mr-4"
                  :label="
                    lang.surveyMaker.conditions.form.args[languageSelected]
                  "
                  :placeholder="
                    lang.surveyMaker.conditions.form.args[languageSelected]
                  "
                  v-model="condition.args[0]"
                />

                <!-- jump -->
                <div class="mr-4 vs-component vs-con-input-label">
                  <label for="" class="vs-input--label">{{
                    lang.surveyMaker.conditions.form.jump[languageSelected]
                  }}</label>
                  <multiselect
                    :allow-empty="false"
                    v-model="condition.jump.group"
                    :showLabels="false"
                    :placeholder="
                      lang.surveyMaker.conditions.form.jump[languageSelected]
                    "
                    :options="surveyGroupOrderNames"
                  />
                </div>

                <vs-button
                  color="danger"
                  type="flat"
                  icon="icon-trash"
                  icon-pack="feather"
                  size="small"
                  class="ml-2 mt-6"
                  radius
                  @click="removeCondition(newItem, conditionIndex)"
                />
              </div>

              <!-- add condition -->
              <vs-button
                type="flat"
                size="small"
                class="mt-2"
                @click="addCondition(newItem)"
              >
                {{ lang.surveyMaker.conditions.add[languageSelected] }}
              </vs-button>
            </vs-col>
          </div>
        </tab-content>

        <!-- name -->
        <tab-content
          :title="lang.surveyMaker.editor.newItem.tab4.title[languageSelected]"
          class="mb-5"
          icon="feather icon-hard-drive"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                :label="
                  lang.surveyMaker.editor.newItem.tab4.saveAs.label[
                    languageSelected
                  ]
                "
                :placeholder="
                  lang.surveyMaker.editor.newItem.tab4.saveAs.placeholder[
                    languageSelected
                  ]
                "
                class="mb-2 w-full"
                v-model="newItem.key"
              />
            </vs-col>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>

    <!-- edit question -->
    <vs-popup title="❓✏️" :active.sync="editItem.popup">
      <form-wizard
        ref="editItemFormWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="lang.surveyMaker.editor.editItem.title[languageSelected]"
        :subtitle="lang.surveyMaker.editor.editItem.subtitle[languageSelected]"
        :nextButtonText="
          lang.surveyMaker.editor.editItem.nextButtonText[languageSelected]
        "
        :backButtonText="
          lang.surveyMaker.editor.editItem.backButtonText[languageSelected]
        "
        :finishButtonText="
          lang.surveyMaker.editor.editItem.finishButtonText[languageSelected]
        "
        @on-complete="editNode"
      >
        <!-- question -->
        <tab-content
          :title="lang.surveyMaker.editor.editItem.tab1.title[languageSelected]"
          class="mb-5"
          icon="feather icon-help-circle"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                :label="
                  lang.surveyMaker.editor.editItem.tab1.name[languageSelected]
                "
                class="mb-4 w-full"
                v-model="editItem.name"
              />
              <vs-textarea
                v-model="editItem.question"
                :label="
                  lang.surveyMaker.editor.editItem.tab1.question[
                    languageSelected
                  ]
                "
              />
              <vs-textarea
                v-model="editItem.requestion"
                :label="
                  lang.surveyMaker.editor.editItem.tab1.requestion[
                    languageSelected
                  ]
                "
              />
            </vs-col>
          </div>
        </tab-content>

        <!-- response -->
        <tab-content
          :title="lang.surveyMaker.editor.editItem.tab2.title[languageSelected]"
          class="mb-5"
          icon="feather icon-target"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <ul class>
                <li
                  v-for="(item, index) in lang.surveyMaker.editor.editItem.tab2
                    .responseTypes[languageSelected]"
                  :key="index"
                >
                  <vs-radio
                    v-model="editItem.responseType"
                    :vs-value="item.value"
                    >{{ item.text }}</vs-radio
                  >
                </li>
              </ul>
            </vs-col>
          </div>
          <div class="vx-row" v-if="editItem.responseType === 1">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-divider />
              <label for>
                {{ lang.surveyMaker.clusterizeAnswers[languageSelected] }}
              </label>
              <vx-slider
                v-model="editItem.clusterizeAnswers"
                vs-icon-on="group_work"
                class="edit-question__clusterize-answer"
              />
            </vs-col>
          </div>
          <div class="vx-row" v-if="editItem.responseType === 3">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-divider />
              <vs-input
                :label="
                  lang.surveyMaker.editor.editItem.tab2.options.label[
                    languageSelected
                  ]
                "
                v-on:keyup.enter="addOption"
                :placeholder="
                  lang.surveyMaker.editor.editItem.tab2.options.placeholder[
                    languageSelected
                  ]
                "
                class="mb-2 w-full"
                v-model="editItem.optionsInput"
              />
              <draggable :list="editItem.options" :class="{ 'cursor-move': isAuthAux('konecta.surveys.edit', true) }">
                <vs-chip
                  @click="removeOption(option)"
                  class="mr-2 mt-3"
                  color="primary"
                  v-for="(option, index) in editItem.options"
                  :key="index"
                  closable
                  >{{ option }}</vs-chip
                >
              </draggable>
            </vs-col>
          </div>
        </tab-content>

        <!-- logic -->
        <tab-content
          :title="lang.surveyMaker.editor.editItem.tab3.title[languageSelected]"
          class="mb-5"
          icon="feather icon-settings"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <p
                class="flex justify-center"
                v-if="editItem.conditions.length === 0"
              >
                {{ lang.surveyMaker.conditions.empty[languageSelected] }}
              </p>
              <!-- condition -->
              <div
                v-for="(condition, conditionIndex) in editItem.conditions"
                :key="conditionIndex"
                class="condition mb-4"
              >
                <!-- func -->
                <div class="mr-4 vs-component vs-con-input-label">
                  <label for="" class="vs-input--label">{{
                    lang.surveyMaker.conditions.form.function[languageSelected]
                  }}</label>
                  <multiselect
                    :allow-empty="false"
                    v-model="condition.func"
                    :showLabels="false"
                    :placeholder="
                      lang.surveyMaker.conditions.form.function[
                        languageSelected
                      ]
                    "
                    :custom-label="
                      opt => SURVEY_CONDITIONS.find(c => c.value == opt).text
                    "
                    :options="SURVEY_CONDITIONS_KEYS"
                  />
                </div>

                <!-- args -->
                <vs-input
                  class="mr-4"
                  :label="
                    lang.surveyMaker.conditions.form.args[languageSelected]
                  "
                  :placeholder="
                    lang.surveyMaker.conditions.form.args[languageSelected]
                  "
                  v-model="condition.args[0]"
                />

                <!-- jump -->
                <div class="mr-4 vs-component vs-con-input-label">
                  <label for="" class="vs-input--label">{{
                    lang.surveyMaker.conditions.form.jump[languageSelected]
                  }}</label>
                  <multiselect
                    :allow-empty="false"
                    v-model="condition.jump.group"
                    :showLabels="false"
                    :placeholder="
                      lang.surveyMaker.conditions.form.jump[languageSelected]
                    "
                    :options="surveyGroupOrderNames"
                  />
                </div>

                <vs-button
                  color="danger"
                  type="flat"
                  icon="icon-trash"
                  icon-pack="feather"
                  size="small"
                  class="ml-2 mt-6"
                  radius
                  @click="removeCondition(editItem, conditionIndex)"
                />
              </div>

              <!-- add condition -->
              <vs-button
                type="flat"
                size="small"
                class="mt-2"
                @click="addCondition(editItem)"
              >
                {{ lang.surveyMaker.conditions.add[languageSelected] }}
              </vs-button>
            </vs-col>
          </div>
        </tab-content>

        <!-- name -->
        <tab-content
          :title="lang.surveyMaker.editor.editItem.tab4.title[languageSelected]"
          class="mb-5"
          icon="feather icon-hard-drive"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                :label="
                  lang.surveyMaker.editor.editItem.tab4.saveAs.label[
                    languageSelected
                  ]
                "
                :placeholder="
                  lang.surveyMaker.editor.editItem.tab4.saveAs.placeholder[
                    languageSelected
                  ]
                "
                class="mb-2 w-full"
                v-model="editItem.key"
              />
            </vs-col>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>

    <!-- edit final message -->
    <vs-popup title="❓✏️" :active.sync="editFinalMessage.popup">
      <form-wizard
        ref="editFinalMessageFormWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="
          lang.surveyMaker.editor.editFinalMessage.title[languageSelected]
        "
        :subtitle="
          lang.surveyMaker.editor.editFinalMessage.subtitle[languageSelected]
        "
        :nextButtonText="
          lang.surveyMaker.editor.editFinalMessage.nextButtonText[
            languageSelected
          ]
        "
        :backButtonText="
          lang.surveyMaker.editor.editFinalMessage.backButtonText[
            languageSelected
          ]
        "
        :finishButtonText="
          lang.surveyMaker.editor.editFinalMessage.finishButtonText[
            languageSelected
          ]
        "
        @on-complete="editFinalMessageNode"
      >
        <tab-content class="mb-5" icon="feather icon-award">
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-textarea
                v-model="editFinalMessage.question"
                :label="
                  lang.surveyMaker.editor.editFinalMessage.tab1.label[
                    languageSelected
                  ]
                "
              />
            </vs-col>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>

    <!-- new message -->
    <vs-popup title="💬✏️" :active.sync="newMessage.popup">
      <form-wizard
        ref="newMessageFormWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="lang.surveyMaker.editor.newMessage.title[languageSelected]"
        :subtitle="
          lang.surveyMaker.editor.newMessage.subtitle[languageSelected]
        "
        :nextButtonText="
          lang.surveyMaker.editor.newMessage.nextButtonText[languageSelected]
        "
        :backButtonText="
          lang.surveyMaker.editor.newMessage.backButtonText[languageSelected]
        "
        :finishButtonText="
          lang.surveyMaker.editor.newMessage.finishButtonText[languageSelected]
        "
        @on-complete="addMessage"
      >
        <tab-content class="mb-5" icon="feather icon-message-circle">
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-textarea
                v-model="newMessage.message"
                :label="
                  lang.surveyMaker.editor.newMessage.tab1.label[
                    languageSelected
                  ]
                "
              />
            </vs-col>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>

    <vs-popup title="💬✏️" :active.sync="editMessage.popup">
      <form-wizard
        ref="editMessageFormWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="lang.surveyMaker.editor.editMessage.title[languageSelected]"
        :subtitle="
          lang.surveyMaker.editor.editMessage.subtitle[languageSelected]
        "
        :nextButtonText="
          lang.surveyMaker.editor.editMessage.nextButtonText[languageSelected]
        "
        :backButtonText="
          lang.surveyMaker.editor.editMessage.backButtonText[languageSelected]
        "
        :finishButtonText="
          lang.surveyMaker.editor.editMessage.finishButtonText[languageSelected]
        "
        @on-complete="editMessageFnc"
      >
        <tab-content class="mb-5" icon="feather icon-message-circle">
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-textarea
                v-model="editMessage.message"
                :label="
                  lang.surveyMaker.editor.editMessage.tab1.label[
                    languageSelected
                  ]
                "
              />
            </vs-col>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>

    <div class="mt-4 mb-4"></div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapState } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import draggable from 'vuedraggable'

import services from '../services'
import parser from '../parser'

const CONDITION_MODEL = {
  func: '',
  args: [],
  jump: {
    group: '',
    name: ''
  }
}

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    validName() {
      return true
    },
    responseTypes() {
      return [
        {
          value: 1,
          text: this.lang.surveyMaker.editor.responseTypes.open[
            this.languageSelected
          ]
        },
        {
          value: 2,
          text: this.lang.surveyMaker.editor.responseTypes.numbers[
            this.languageSelected
          ]
        },
        {
          value: 3,
          text: this.lang.surveyMaker.editor.responseTypes.options[
            this.languageSelected
          ]
        }
      ]
    },
    surveyGroupOrder() {
      return Object.values(this.surveyGroups).sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    },
    surveyGroupOrderNames() {
      const selectedGroupIndex = this.selectedGroup
        ? this.surveyGroupOrder.findIndex(
            item => item.group === this.selectedGroup
          )
        : -1
      return this.surveyGroupOrder
        .map(sgo => sgo.group)
        .filter(
          (sg, index) => sg !== this.selectedGroup && index > selectedGroupIndex
        )
    },
    tabsStyle() {
      return `
        background: white;
        height: 40px;
        border: 3px solid transparent;
        margin-top: 8px;
        border-top: 0;
        border-bottom: 0;
        align-self: flex-start;
        width: calc(100% - 50px);
      `
    }
  },
  props: ['surveyId', 'version'],
  watch: {
    version: async function(newVersion) {
      if (newVersion && newVersion.length === 24) {
        this.$vs.loading()
        await this.getQuestions({ newVersion: true })
        this.konaTabsKey = new Date().getTime().toString()
        this.$vs.loading.close()
      }
    },
    survey() {
      this.getSurveyByGroups({})
    }
  },
  components: {
    FormWizard,
    TabContent,
    draggable,
    KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    Multiselect: () => import('vue-multiselect'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      konaTabsKey: new Date().getTime().toString(),
      aux: 0,
      hoverIndex: null,
      // responseTypes: [],
      original: [],
      survey: null,
      surveyGroups: {},
      newMessage: {
        index: null,
        popup: false,
        message: ''
      },
      editMessage: {
        index: null,
        popup: false,
        message: ''
      },
      newItem: {
        index: null,
        popup: false,
        name: '',
        question: '',
        requestion: '',
        responseType: 1,
        optionsInput: '',
        options: [],
        key: '',
        clusterizeAnswers: false,
        group: '',
        conditions: []
      },
      editItem: {
        index: null,
        popup: false,
        name: '',
        question: '',
        requestion: '',
        responseType: 1,
        optionsInput: '',
        options: [],
        key: '',
        clusterizeAnswers: false,
        group: '',
        conditions: []
      },
      editFinalMessage: {
        popup: false,
        question: ''
      },
      SURVEY_CONDITIONS: [],
      SURVEY_CONDITIONS_KEYS: [],
      selectedGroup: null,
      newGroup: false
      // CONDITIONS_SIMBOLS
    }
  },
  methods: {
    dragMessage() {
      if (!this.isAuthAux('konecta.surveys.edit', true)) {
        return false
      }
      this.save()
    },
    openNewMessagePopUp(index) {
      // if (typeof index === 'number') {
      //   this.newMessage.index = index
      // } else {
      //   this.newMessage.index = null
      // }
      this.newMessage.index = index
      this.newMessage.popup = true
    },
    async addMessage() {
      if (!this.newMessage.message || this.newMessage.message === '') {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationError.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationError.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      const newMessage = {
        isMessage: true,
        question: this.newMessage.message,
        group: this.newMessage.group || this.selectedGroup
      }
      // if (typeof this.newMessage.index === 'number') {
      //   this.survey.splice(this.newMessage.index, 0, newMessage)
      // } else {
      //   this.survey.splice(this.survey.length - 1, 0, newMessage)
      // }

      const groupIndex = this.surveyGroupOrder.findIndex(
        s => s.group === this.selectedGroup
      )
      this.surveyGroupOrder[groupIndex].model.splice(
        this.newMessage.index,
        0,
        newMessage
      )

      this.newMessage.popup = false
      await this.save()

      this.aux += 1
      this.newMessage.message = ''
      this.$refs.newMessageFormWizard.reset()
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    toggleEditMessage(item) {
      // if (typeof index === 'number' && this.survey[index]) {
      //   this.editMessage.index = index
      //   this.editMessage.popup = true
      //   this.editMessage.message = this.survey[index].question
      //   this.$refs.editMessageFormWizard.reset()
      // }
      const index = this.survey.findIndex(s => s.key === item.key)
      if (index >= 0) {
        this.editMessage.index = index
        this.editMessage.popup = true
        this.editMessage.message = this.survey[index].question
        this.$refs.editMessageFormWizard.reset()
      }
    },
    async editMessageFnc() {
      if (!this.editMessage.message || this.editMessage.message === '') {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationError.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationError.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }

      // this.survey[this.editMessage.index].question = this.editMessage.message
      const index = this.survey.findIndex(s => s.key === this.editMessage.key)
      this.survey[index].question = this.editMessage.message

      this.editMessage.popup = false
      await this.save()

      this.aux += 1
      this.$refs.editMessageFormWizard.reset()
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },

    openNewItemPopUp(index, isNewGroup = false) {
      // if (typeof index === 'number') {
      //   this.newItem.index = index
      // } else {
      //   this.newItem.index = null
      // }
      this.newGroup = isNewGroup
      this.newItem.index = index
      this.newItem.popup = true
    },
    toogleEditFinalMessage() {
      // if (
      //   this.survey.length > 0 &&
      //   this.survey[this.survey.length - 1].finish
      // ) {
      //   this.editFinalMessage.question = this.survey[
      //     this.survey.length - 1
      //   ].question
      //   this.editFinalMessage.popup = true
      //   this.$refs.editFinalMessageFormWizard.reset()
      // }
      const index = this.survey.findIndex(s => s.finish)
      if (index >= 0) {
        this.editFinalMessage.question = this.survey[index].question
        this.editFinalMessage.popup = true
        this.$refs.editFinalMessageFormWizard.reset()
      }
    },
    toggleEdit(item) {
      const index = this.survey.findIndex(s => s.key === item.key)
      if (typeof index === 'number' && this.survey[index]) {
        this.editItem.index = index
        this.editItem.popup = true
        this.editItem.name = this.survey[index].name
        this.editItem.question = this.survey[index].question
        this.editItem.requestion = this.survey[index].requestion || ''
        this.editItem.responseType = this.survey[index].responseType
        this.editItem.options = this.survey[index].options || []
        this.editItem.optionsInput = ''
        this.editItem.key = this.survey[index].key
        this.editItem.clusterizeAnswers =
          this.survey[index].clusterizeAnswers || false
        this.editItem.group = this.survey[index].group
        this.editItem.conditions = this.survey[index].conditions

        this.$refs.editItemFormWizard.reset()
      }
    },
    updateQuestionsIndexes() {
      if (this.surveyGroupOrder.length > 0) {
        const a = this.surveyGroupOrder.reduce((acc, item) => {
          acc.push(...item.model)
          return acc
        }, [])

        a.forEach((item, index) => (item.index = index))
        this.survey = a
      }
    },
    async save(skipLoading = false) {
      if (!skipLoading) {
        this.$vs.loading()
      }

      this.updateQuestionsIndexes()

      const aux = JSON.parse(JSON.stringify(this.survey))
      aux.forEach((item, index) => {
        if (item.clusterizeAnswers && item.responseType === 1) {
          delete item.clusterizeAnswers
          item.clusterize = {
            active: true,
            interval: 1000 * 60 * 5 // 5 minutos para probar
          }
        }

        // last question is always 'Final'
        let groupName
        if (item.group) {
          groupName = item.group
        } else {
          groupName = index === aux.length - 1 ? 'Final' : `${index + 1}`
        }
        item.group = groupName
        // each question of a new survey has index 0 in its group (nps & final message)
        // item.index = 0
      })
      try {
        const result = await services.putSurveyDialogs(
          this.surveyId,
          this.version,
          aux
        )
        this.survey = parser.splitSurvey(result.data)
        // this.survey.forEach((element, index) => {
        //   element.index = index
        // })
        this.original = JSON.parse(JSON.stringify(this.survey))
        // this.getSurveyByGroups({})
        // this.$vs.loading.close();
      } catch (error) {
        // TODO: MOSTRAR UN ERROR AL USUARIO.
        console.error('error', error)
      }
      if (!skipLoading) {
        this.$vs.loading.close()
      }
    },
    checkMove(event) {
      if (
        event &&
        event.draggedContext &&
        event.draggedContext.element &&
        event.draggedContext.element.finish
      ) {
        return false
      }
      if (!this.isAuthAux('konecta.surveys.edit', true)) {
        return false
      }
      // if (
      //   event &&
      //   event.draggedContext &&
      //   typeof event.draggedContext.futureIndex === 'number' &&
      //   event.draggedContext.futureIndex === this.survey.length - 1
      // ) {
      //   return false
      // }
      return true
    },
    getOnlyPlatformAll(tree) {
      if (!tree.length) {
        return []
      }

      const filteredChildren = []
      tree.forEach(node => {
        const item = { ...node }
        item.children = this.getOnlyPlatformAll(node.children)
        item.responses = node.responses.filter(
          r => r.platforms.includes('all') && r.platforms.length === 1
        )
        filteredChildren.push(item)
      })
      return filteredChildren
    },
    getSurveyByGroups({ newVersion = false }) {
      this.surveyGroups = this.survey.reduce((r, a, i) => {
        r[a.group] = r[a.group] || {
          group: a.group,
          order: i,
          model: []
        }

        r[a.group].model.push(a)
        return r
      }, {})

      // set first group as selected (to add questions without selecting any group)
      if (newVersion || !this.selectedGroup) {
        this.selectedGroup = Object.keys(this.surveyGroups)[0]
      }
    },
    async getQuestions({ newVersion = false }) {
      // TODO: PAGINADO.
      try {
        const response = await services.getSurveyTree(this.version)
        const tree = this.getOnlyPlatformAll(response.data)
        const aux = parser.treeToSurvey3(tree)
        this.survey = []
        aux.forEach((element, index) => {
          this.survey.push({
            index,
            name: element.name,
            question: element.question,
            requestion: element.requestion,
            responseType: element.responseType,
            options: element.options,
            key: element.key,
            finish: index === aux.length - 1,
            isMessage: element.isMessage || false,
            clusterizeAnswers: element.clusterizeAnswers || false,
            group: element.group || '1',
            conditions: element.conditions || []
          })
        })

        if (this.survey.length === 0) {
          this.survey = this.lang.surveyMaker.defaultSurvey[
            this.languageSelected
          ]
          this.original = JSON.parse(JSON.stringify(this.survey))
          await this.save(true)
        } else {
          this.original = JSON.parse(JSON.stringify(this.survey))
        }
        this.getSurveyByGroups({ newVersion })
      } catch (error) {
        // TODO: MOSTRAR UN ERROR AL USUARIO.
        console.error('error', error)
      }
    },
    clearNewItem() {
      this.newItem.index = null
      this.newItem.name = ''
      this.newItem.question = ''
      this.newItem.requestion = ''
      this.newItem.responseType = 1
      this.newItem.optionsInput = ''
      this.newItem.options = []
      this.newItem.key = ''
      this.newItem.clusterizeAnswers = false
      this.newItem.group = ''
      this.newItem.conditions = []
      this.newGroup = false
      // this.$refs.newItemFormWizard.reset()
    },
    clearEditItem() {
      this.editItem.index = null
      this.editItem.name = ''
      this.editItem.question = ''
      this.editItem.requestion = ''
      this.editItem.responseType = 1
      this.editItem.optionsInput = ''
      this.editItem.options = []
      this.editItem.key = ''
      this.editItem.clusterizeAnswers = false
      this.editItem.group = ''
      this.editItem.conditions = []
      this.newGroup = false
      this.$refs.editItemFormWizard.reset()
    },
    removeNode(item) {
      const index = item.index;
      //TODO: refactor
      // if (this.survey[index].isMessage) {
      //   this.$vs.dialog({
      //     type: 'confirm',
      //     color: 'danger',
      //     title: this.lang.surveyMaker.editor.removeMessage.dialog.title[
      //       this.languageSelected
      //     ],
      //     text: this.lang.surveyMaker.editor.removeMessage.dialog.text[
      //       this.languageSelected
      //     ],
      //     accept: async () => {
      //       this.survey.splice(index, 1)
      //       this.getSurveyByGroups({})
      //       await this.save()
      //     }
      //   })
      // } else {
      //   this.$vs.dialog({
      //     type: 'confirm',
      //     color: 'danger',
      //     title: this.lang.surveyMaker.editor.removeQuestion.dialog.title[
      //       this.languageSelected
      //     ],
      //     text: this.lang.surveyMaker.editor.removeQuestion.dialog.text[
      //       this.languageSelected
      //     ],
      //     accept: async () => {
      //       this.survey.splice(index, 1)
      //       this.getSurveyByGroups({})
      //       await this.save()
      //     }
      //   })
      // }

      const currentJumps = this.validateJumps(item)
      if (currentJumps.length > 0) {
        this.$vs.dialog({
          title: this.lang.surveyMaker.editor.validationError.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.conditions.messages.error.activeJumps[
            this.languageSelected
          ]
            .replace('XXX', item.name)
            .replace('YYY', currentJumps),
          color: 'warning'
        })
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.survey[index].isMessage
          ? this.lang.surveyMaker.editor.removeMessage.dialog.title[
              this.languageSelected
            ]
          : this.lang.surveyMaker.editor.removeQuestion.dialog.title[
              this.languageSelected
            ],
        text: this.survey[index].isMessage
          ? this.lang.surveyMaker.editor.removeMessage.dialog.text[
              this.languageSelected
            ]
          : this.lang.surveyMaker.editor.removeQuestion.dialog.text[
              this.languageSelected
            ],
        accept: () => {
          const isMessage = this.survey[index].isMessage
          this.survey.splice(index, 1)
          if (!isMessage) {
            this.removeAssociatedMessages(index - 1)
          }
          this.getSurveyByGroups({})
          this.save()
        }
      })
    },
    validateJumps(item) {
      const groupName = item.group
      const groupCount = this.survey.filter(s => s.group === groupName).length
      // if removing last group item
      if (groupCount === 1) {
        // const conditions = this.survey.map(s => s.conditions)
        // const jumpsToGroup = conditions.filter(condition =>
        //   condition.some(c => c.jump && c.jump.group === groupName)
        // ).length

        //
        // return jumpsToGroup === 0

        const surveyWithConditions = this.survey.filter(
          s => s.conditions.length > 0
        )
        const jumpsToGroup = surveyWithConditions.filter(s =>
          s.conditions.some(c => c.jump.group === groupName)
        )
        // list jumps to this item
        const listNames = jumpsToGroup.map(s => s.name).join(', ')
        return listNames
      }
      return true
    },
    removeAssociatedMessages(index) {
      let questionFound = false
      while (!questionFound && index >= 0) {
        if (this.survey[index].isMessage) {
          this.survey.splice(index, 1)
          index = index - 1
        } else {
          questionFound = true
        }
      }
    },
    editFinalMessageNode() {
      if (
        !this.editFinalMessage.question ||
        this.editFinalMessage.question === ''
      ) {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationError.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationError.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      // this.survey[
      //   this.survey.length - 1
      // ].question = this.editFinalMessage.question

      this.editFinalMessage.popup = false

      const index = this.survey.findIndex(s => s.finish)
      this.survey[index].question = this.editFinalMessage.question
      this.save()
    },
    async editNode() {
      if (
        !this.editItem.name ||
        this.editItem.name === '' ||
        !this.editItem.question ||
        this.editItem.question === '' ||
        !this.editItem.key ||
        this.editItem.key === ''
      ) {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationError.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationError.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      if (!/^[a-zA-Z0-9 _-]+$/.test(this.editItem.key)) {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationErrorKey.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationErrorKey.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      if (
        this.editItem.responseType === 3 &&
        this.editItem.options.length < 2
      ) {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationErrorOptions.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationErrorOptions.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      // all conditions have jump, func & args
      if (this.editItem.conditions.length > 0) {
        if (
          !this.editItem.conditions.every(
            c => c.jump && c.jump.group && c.jump.group !== ''
          )
        ) {
          this.$vs.notify({
            title: this.lang.surveyMaker.editor.validationErrorOptions.title[
              this.languageSelected
            ],
            text: this.lang.surveyMaker.conditions.messages.error.missingJumps[
              this.languageSelected
            ],
            color: 'danger',
            position: 'bottom-right'
          })
          return
        }
        if (!this.editItem.conditions.every(c => c.func && c.func !== '')) {
          this.$vs.notify({
            title: this.lang.surveyMaker.editor.validationErrorOptions.title[
              this.languageSelected
            ],
            text: this.lang.surveyMaker.conditions.messages.error.missingFuncs[
              this.languageSelected
            ],
            color: 'danger',
            position: 'bottom-right'
          })
          return
        }
        if (
          !this.editItem.conditions.every(
            c => c.args && c.args[0] && c.args[0] !== ''
          )
        ) {
          this.$vs.notify({
            title: this.lang.surveyMaker.editor.validationErrorOptions.title[
              this.languageSelected
            ],
            text: this.lang.surveyMaker.conditions.messages.error.missingArgs[
              this.languageSelected
            ],
            color: 'danger',
            position: 'bottom-right'
          })
          return
        }
      }
      this.survey[this.editItem.index].name = this.editItem.name
      this.survey[this.editItem.index].question = this.editItem.question
      this.survey[this.editItem.index].requestion =
        this.editItem.requestion && this.editItem.requestion.length > 0
          ? this.editItem.requestion
          : null
      this.survey[this.editItem.index].responseType = this.editItem.responseType
      this.survey[this.editItem.index].options =
        this.editItem.responseType === 3
          ? JSON.parse(JSON.stringify(this.editItem.options))
          : null
      this.survey[this.editItem.index].key = this.editItem.key
      this.survey[this.editItem.index].clusterizeAnswers =
        this.editItem.clusterizeAnswers || false
      this.survey[this.editItem.index].group =
        this.editItem.group || this.selectedGroup
      this.survey[this.editItem.index].conditions = this.editItem.conditions

      this.editItem.popup = false
      await this.save()
      this.aux += 1
      this.clearEditItem()
    },
    async addNode() {
      if (
        !this.newItem.name ||
        this.newItem.name === '' ||
        !this.newItem.question ||
        this.newItem.question === '' ||
        !this.newItem.key ||
        this.newItem.key === ''
      ) {
        // !this.newItem.group ||
        // this.newItem.group === ''
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationError.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationError.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      if (!/^[a-zA-Z0-9 _-]+$/.test(this.newItem.key)) {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationErrorKey.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationErrorKey.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }
      if (this.newItem.responseType === 3 && this.newItem.options.length < 2) {
        this.$vs.notify({
          title: this.lang.surveyMaker.editor.validationErrorOptions.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.editor.validationErrorOptions.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        return
      }

      // all conditions have jump, func & args
      if (this.newItem.conditions.length > 0) {
        if (
          !this.newItem.conditions.every(
            c => c.jump && c.jump.group && c.jump.group !== ''
          )
        ) {
          this.$vs.notify({
            title: this.lang.surveyMaker.editor.validationErrorOptions.title[
              this.languageSelected
            ],
            text: this.lang.surveyMaker.conditions.messages.error.missingJumps[
              this.languageSelected
            ],
            color: 'danger',
            position: 'bottom-right'
          })
          return
        }
        if (!this.newItem.conditions.every(c => c.func && c.func !== '')) {
          this.$vs.notify({
            title: this.lang.surveyMaker.editor.validationErrorOptions.title[
              this.languageSelected
            ],
            text: this.lang.surveyMaker.conditions.messages.error.missingFuncs[
              this.languageSelected
            ],
            color: 'danger',
            position: 'bottom-right'
          })
          return
        }
        if (
          !this.newItem.conditions.every(
            c => c.args && c.args[0] && c.args[0] !== ''
          )
        ) {
          this.$vs.notify({
            title: this.lang.surveyMaker.editor.validationErrorOptions.title[
              this.languageSelected
            ],
            text: this.lang.surveyMaker.conditions.messages.error.missingArgs[
              this.languageSelected
            ],
            color: 'danger',
            position: 'bottom-right'
          })
          return
        }
      }

      const newItem = {
        name: this.newItem.name,
        question: this.newItem.question,
        requestion:
          this.newItem.requestion && this.newItem.requestion.length > 0
            ? this.newItem.requestion
            : null,
        responseType: this.newItem.responseType,
        options:
          this.newItem.responseType === 3
            ? JSON.parse(JSON.stringify(this.newItem.options))
            : null,
        key: this.newItem.key,
        clusterizeAnswers: this.newItem.clusterizeAnswers || false,
        group: this.newItem.group || this.selectedGroup,
        conditions: this.newItem.conditions
      }

      // if (typeof this.newItem.index === 'number') {
      //   this.survey.splice(this.newItem.index, 0, newItem)
      // } else {
      //   this.survey.splice(this.survey.length - 1, 0, newItem)
      // }

      // console.warn('new item', this.newItem.index, this.selectedGroup)
      // console.warn(this.surveyGroupOrder)

      let groupIndex = this.surveyGroupOrder.findIndex(
        s => s.group === this.selectedGroup
      )
      if (this.newGroup) {
        groupIndex =
          groupIndex + 1 === this.surveyGroupOrder.length
            ? groupIndex
            : groupIndex + 1
      }
      // if (this.newGroup) {
      //   const group = {
      //     group: newItem.group,
      //     order: groupIndex + 1,
      //     model: [newItem]
      //   }

      //   this.surveyGroupOrder.push(group)
      // } else {
      this.surveyGroupOrder[groupIndex].model.splice(
        this.newItem.index,
        0,
        newItem
      )
      // }
      this.newItem.popup = false
      await this.save()

      this.aux += 1
      this.clearNewItem()
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    addOption() {
      if (this.newItem.popup) {
        if (this.newItem.optionsInput && this.newItem.optionsInput.length > 0) {
          this.newItem.options.push(this.newItem.optionsInput)
          this.newItem.optionsInput = null
        }
      }
      if (this.editItem.popup) {
        if (
          this.editItem.optionsInput &&
          this.editItem.optionsInput.length > 0
        ) {
          this.editItem.options.push(this.editItem.optionsInput)
          this.editItem.optionsInput = null
        }
      }
    },
    removeOption(item) {
      if (this.newItem.popup) {
        this.newItem.options.splice(this.newItem.options.indexOf(item), 1)
      }
      if (this.editItem.popup) {
        this.editItem.options.splice(this.editItem.options.indexOf(item), 1)
      }
    },
    // addCondition(item) {
    //   const surveyIndex = this.survey.findIndex(
    //     survey => survey.key === item.key
    //   )
    //   this.survey[surveyIndex].conditions.push(CONDITION_MODEL)
    // },
    addCondition(item) {
      item.conditions.push(this._.cloneDeep(CONDITION_MODEL))
    },
    // removeCondition(item, conditionIndex) {
    //   const surveyIndex = this.survey.findIndex(
    //     survey => survey.key === item.key
    //   )
    //   this.survey[surveyIndex].conditions.splice(conditionIndex, 1)
    //   console.warn(this.survey[surveyIndex])
    // },
    removeCondition(item, conditionIndex) {
      item.conditions.splice(conditionIndex, 1)
    },
    onGroupChange(selectedGroup) {
      this.selectedGroup = selectedGroup
    },
    initConditions() {
      this.SURVEY_CONDITIONS = [
        {
          text: this.lang.surveyMaker.conditions.texts.lessThan[
            this.languageSelected
          ],
          value: 'lessThan'
        },
        {
          text: this.lang.surveyMaker.conditions.texts.greaterThan[
            this.languageSelected
          ],
          value: 'greaterThan'
        },
        {
          text: this.lang.surveyMaker.conditions.texts.equal[
            this.languageSelected
          ],
          value: 'equal'
        },
        // { text: 'Not Equal', value: 'not_equal' },
        {
          text: this.lang.surveyMaker.conditions.texts.match[
            this.languageSelected
          ],
          value: 'match'
        }
      ]
      this.SURVEY_CONDITIONS_KEYS = this.SURVEY_CONDITIONS.map(c => c.value)
    }
  },
  async created() {
    this.$vs.loading()
    this.initConditions()
    await this.getQuestions({ newVersion: true })
    this.$vs.loading.close()
  }
}
</script>

<style lang="scss">
.survey-editor {
  .tabs-actions {
    right: 0;
    margin-top: 9px;
    z-index: 3;
  }
}

.condition {
  display: flex;
  flex-grow: 1;
  width: auto;
  align-items: center;
}

.save-cancel {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 999999;
  background-image: linear-gradient(to right, transparent, #cecccc);
}
.survey-editor #kona-tabs .navigation-tab::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(138, 128, 128, 0.5);
  box-shadow: inset 0 0 6px rgba(138, 128, 128, 0.5);
}

.survey-editor #kona-tabs .navigation-tab::-webkit-scrollbar {
  display: block;
  height: 5px !important;
  overflow: auto;
}
</style>
