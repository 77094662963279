const JUMP_MARK = '~'

function saveSurveyResponse(actions) {
  let i = null
  ;(actions || []).some((element, index) => {
    if (element.key === 'save survey response') {
      i = index
      return true
    }
  })
  if (i !== null) {
    return actions[i].params[0]
  }
  return null
}

function treeToSurvey(tree) {
  if (!tree || tree.length === 0) {
    return []
  }
  const index = tree.findIndex(item => item.meta === 'main') // TECKO-373, because the first one could be a condition jump
  const nextIndex = (tree[index].children || []).findIndex(
    item => item.meta === 'main'
  )
  // const treeResponses = tree[index].responses.filter(q => q.platforms.indexOf('all') > -1);
  const node1 = {
    name: tree[index].name,
    question: tree[index].responses[0].responseText,
    requestion:
      tree[index].children &&
      tree[index].children[nextIndex + 1] &&
      tree[index].children[nextIndex + 1].responses[0].responseText &&
      tree[index].children[nextIndex + 1].responses[0].responseText !==
        tree[index].responses[0].responseText
        ? tree[index].children[nextIndex + 1].responses[0].responseText
        : null,
    responseType: tree[index].responses[0].responseOptions
      ? 3
      : tree[index].children && tree[index].children[nextIndex]
      ? tree[index].children[nextIndex].condition &&
        tree[index].children[nextIndex].condition.params &&
        tree[index].children[nextIndex].condition.params[0] &&
        tree[index].children[nextIndex].condition.params[0].regex &&
        tree[index].children[nextIndex].condition.params[0].regex === '^\\d+$'
        ? 2
        : 1
      : null,
    options: tree[index].responses[0].responseOptions
      ? tree[index].responses[0].responseOptions.map(element => {
          return element.text
        })
      : null,
    key: saveSurveyResponse(
      tree[index].children && tree[index].children[nextIndex]
        ? tree[index].children[nextIndex].actions
        : []
    ),
    finish:
      tree[index] &&
      (!tree[index].children || tree[index].children.length === 0),
    clusterizeAnswers:
      tree[index] &&
      tree[index].children &&
      tree[index].children[nextIndex] &&
      tree[index].children[nextIndex].dialogClusterSettings
  }
  if (tree[index].children && tree[index].children[nextIndex]) {
    return [node1].concat(treeToSurvey(tree[index].children))
  }
  return [node1]
}

// eslint-disable-next-line no-unused-vars
function _getName(tree, index, nextIndex) {
  return tree[index].name
}

// eslint-disable-next-line no-unused-vars
function _getQuestion(tree, index, nextIndex) {
  return tree[index].responses.map(element => element.responseText)
}

function _getRequestion(tree, index, nextIndex) {
  if (
    tree[index].children &&
    tree[index].children[nextIndex + 1] &&
    tree[index].children[nextIndex + 1].responses[0].responseText &&
    tree[index].children[nextIndex + 1].responses[0].responseText !==
      tree[index].responses[0].responseText
  ) {
    return tree[index].children[nextIndex + 1].responses[0].responseText
  }
  return null
}

function _getResponseType(tree, index, nextIndex) {
  if (tree[index].responses[tree[index].responses.length - 1].responseOptions) {
    return 3 // opciones
  }
  if (tree[index].children && tree[index].children[nextIndex]) {
    if (
      tree[index].children[nextIndex].condition &&
      tree[index].children[nextIndex].condition.params &&
      tree[index].children[nextIndex].condition.params[0] &&
      tree[index].children[nextIndex].condition.params[0].regex &&
      tree[index].children[nextIndex].condition.params[0].regex === '^\\d+$'
    ) {
      return 2 // números
    }
    return 1 // texto libre
  }
  return null
}

// eslint-disable-next-line no-unused-vars
function _getOptions(tree, index, nextIndex) {
  if (tree[index].responses[tree[index].responses.length - 1].responseOptions) {
    return tree[index].responses[
      tree[index].responses.length - 1
    ].responseOptions.map(element => element.text)
  }
  return null
}

function _getKey(tree, index, nextIndex) {
  if (tree[index].children && tree[index].children[nextIndex]) {
    let i = null
    ;(tree[index].children[nextIndex].actions || []).some((element, index) => {
      if (element.key === 'save survey response') {
        i = index
        return true
      }
    })
    if (i !== null) {
      return tree[index].children[nextIndex].actions[i].params[0]
    }
    return null
  }
  return null
}

// eslint-disable-next-line no-unused-vars
function _getFinish(tree, index, nextIndex) {
  return (
    tree[index] && (!tree[index].children || tree[index].children.length === 0)
  )
}

function _getClusterizeAnswers(tree, index, nextIndex) {
  if (
    tree[index] &&
    tree[index].children &&
    tree[index].children[nextIndex] &&
    tree[index].children[nextIndex].dialogClusterSettings
  ) {
    return true
  }
  return false
}

// eslint-disable-next-line no-unused-vars
function _getGroup(tree, index, nextIndex) {
  return tree[index] ? tree[index].group : null
}

// eslint-disable-next-line no-unused-vars
function _getConditions(tree, index, nextIndex) {
  return (tree[index].children || [])
    .filter((_, i) => i < nextIndex)
    .map(item => ({
      func: item.condition.tree.funcs[0],
      args:
        item.condition.tree.funcs[0] === 'match'
          ? [item.condition.params[0].regex]
          : [item.condition.params[0].arg0],
      jump: {
        group: item.meta.split(', ')[1].split(JUMP_MARK)[0],
        name: item.meta.split(', ')[1].split(JUMP_MARK)[1]
      }
    }))
}

function treeToSurvey2(tree) {
  const index = tree.findIndex(item => item.meta === 'main') // TECKO-373, because the first one could be a condition jump
  if (!tree || tree.length === 0) {
    return []
  }
  const nextIndex = (tree[index].children || []).findIndex(
    item => item.meta === 'main'
  )
  const node1 = {
    name: _getName(tree, index, nextIndex),
    question: _getQuestion(tree, index, nextIndex),
    requestion: _getRequestion(tree, index, nextIndex),
    responseType: _getResponseType(tree, index, nextIndex),
    options: _getOptions(tree, index, nextIndex),
    key: _getKey(tree, index, nextIndex),
    finish: _getFinish(tree, index, nextIndex),
    clusterizeAnswers: _getClusterizeAnswers(tree, index, nextIndex),
    group: _getGroup(tree, index, nextIndex),
    conditions: _getConditions(tree, index, nextIndex)
  }
  if (tree[index].children && tree[index].children[nextIndex]) {
    return [node1].concat(treeToSurvey2(tree[index].children))
  }
  return [node1]
}

function treeToSurvey3(tree) {
  const survey = treeToSurvey2(tree)
  return splitSurvey(survey)
}

// separa las preguntas con varios textos en question
function splitSurvey(survey) {
  const aux = []
  survey.forEach(element => {
    element.question.forEach((text, index) => {
      if (index === element.question.length - 1) {
        aux.push({
          name: element.name,
          question: text,
          requestion: element.requestion,
          responseType: element.responseType,
          options: element.options,
          key: element.key,
          finish: element.finish,
          clusterizeAnswers: element.clusterizeAnswers,
          group: element.group,
          conditions: element.conditions,
          isMessage: false,
        })
      } else {
        aux.push({
          isMessage: true,
          question: text,
          group: element.group,
        })
      }
    })
  })
  if (aux.length > 0) {
    aux[aux.length - 1].group = 'Final'
  }
  aux.forEach((element, index) => {
    element.index = index
  })
  return aux
}

export default {
  treeToSurvey,
  treeToSurvey2,
  treeToSurvey3,
  splitSurvey
}
