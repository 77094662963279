<template>
  <div>
    <div class="p-6 zap-button" v-if="selected && versionSelected">
      <vs-button
        class="float-right"
        v-if="!tryIt"
        radius
        color="success"
        type="gradient"
        icon-pack="feather"
        icon="icon-zap"
        :disabled="tryIt"
        @click="tryIt = !tryIt"
        :title="lang.surveyMaker.surveys.preview[languageSelected]"
      ></vs-button>
    </div>
    <div class="vx-row">
      <vs-col :vs-lg="'5'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-breadcrumb class="mb-4" :items="breadcrumb"></vs-breadcrumb>
        <vx-card
          :title="lang.surveyMaker.surveys.title[languageSelected]"
          :subtitle="lang.surveyMaker.surveys.subtitle[languageSelected]"
          class="overflow-hidden"
        >
          <vs-table
            class="mt-4"
            v-model="selected"
            pagination
            max-items="5"
            sorted
            @selected="handleSelected"
            :data="surveys"
            search
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sort-key="name">
                {{ lang.surveyMaker.surveys.table.name[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{
                  lang.surveyMaker.surveys.table._createdAt[languageSelected]
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">
                  {{ data[indextr].name }}
                </vs-td>

                <vs-td :data="data[indextr]._createdAt">
                  {{ new Date(data[indextr]._createdAt) | date(true) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="push-right">
            <vs-divider />
            <vs-button
              v-if="selected && isAuthAux('konecta.surveys.delete', true)"
              class="float-right mb-3"
              icon-pack="feather"
              icon="icon-trash-2"
              type="flat"
              color="danger"
              @click="toggleDeleteSurvey"
            >
              {{ lang.surveyMaker.surveys.deleteSurvey[languageSelected] }}
            </vs-button>
            <vs-button
              @click="
                $router.push(
                  `surveys-integrations?survey=${selected._id}&service=${selected.service}`
                )
              "
              v-if="selected && isAuthAux('konecta.surveys.send', true)"
              class
              type="flat"
              :color="'#9ecc38'"
              :gradient-color-secondary="'#3EC9D6'"
              icon="icon-send"
              icon-pack="feather"
            >
              {{ lang.surveyMaker.surveyIntegrations[languageSelected] }}
            </vs-button>
          </div>
          <div class="push-right">
            <vs-button
              v-if="isAuthAux('konecta.surveys.add', true)"
              class="mb-4 mt-4"
              type="flat"
              icon="icon-plus"
              icon-pack="feather"
              @click="newSurvey.popup = true"
            >
              {{ lang.surveyMaker.surveys.addSurvey[languageSelected] }}
            </vs-button>
          </div>
        </vx-card>

        <div style="height: 10px"></div>
        <div v-if="selected">
          <vx-card
            :title="lang.surveyMaker.versions.title[languageSelected]"
            :subtitle="lang.surveyMaker.versions.subtitle[languageSelected]"
            class="overflow-hidden mt-2"
          >
            <p v-if="notActiveVersion" class="text-danger">
              {{
                lang.surveyMaker.versions.noProductionVersionWarning[
                  languageSelected
                ]
              }}
            </p>
            <vs-table
              class="mt-4"
              v-model="versionSelected"
              pagination
              max-items="5"
              sorted
              @selected="handleSelectedVersion"
              :data="versions"
              search
              :no-data-text="lang.general.noDataText[languageSelected]"
            >
              <template slot="thead">
                <vs-th>
                  {{ lang.surveyMaker.versions.table.tag[languageSelected] }}
                </vs-th>
                <vs-th sort-key="status">
                  {{ lang.surveyMaker.versions.table.status[languageSelected] }}
                </vs-th>
                <vs-th sort-key="_createdAt">
                  {{
                    lang.surveyMaker.versions.table._createdAt[languageSelected]
                  }}
                </vs-th>
                <vs-th v-if="isAuthAux('konecta.surveys.edit', true)">
                  {{ lang.surveyMaker.versions.table.edit[languageSelected] }}
                </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].tag">
                    {{ data[indextr].tag }}
                  </vs-td>

                  <vs-td :data="data[indextr].status">
                    <vs-chip
                      :color="
                        data[indextr].status === 'production' ? 'success' : ''
                      "
                    >
                      {{
                        lang.surveyMaker[data[indextr].status][languageSelected]
                      }}
                    </vs-chip>
                  </vs-td>
                  <vs-td :data="data[indextr]._createdAt">
                    {{ new Date(data[indextr]._createdAt) | date(true) }}
                  </vs-td>
                  <vs-td v-if="isAuthAux('konecta.surveys.edit', true)">
                    <vs-button
                      color="warning"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-edit"
                      @click="editVersion"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div class="push-right">
              <vs-divider />
              <vs-button
                v-if="versionSelected && isAuthAux('konecta.surveys.edit', true)"
                class="float-right mb-3"
                icon-pack="feather"
                icon="icon-trash-2"
                type="flat"
                color="danger"
                @click="deleteVersion"
              >
                {{ lang.surveyMaker.versions.delete[languageSelected] }}
              </vs-button>
              <vs-button
                v-show="versionSelected && !versionSelected.active && isAuthAux('konecta.surveys.edit', true)"
                class
                icon-pack="feather"
                icon="icon-arrow-up"
                type="flat"
                :color="'#9ecc38'"
                :gradient-color-secondary="'#3EC9D6'"
                @click="promoteVersion"
              >
                {{ lang.surveyMaker.versions.promote[languageSelected] }}
              </vs-button>
            </div>
            <div class="push-right">
              <vs-button
                v-if="isAuthAux('konecta.surveys.edit', true)"
                type="flat"
                class="mb-4 mt-4"
                icon="icon-plus"
                icon-pack="feather"
                @click="addVersion"
              >
                {{ lang.surveyMaker.versions.add[languageSelected] }}
              </vs-button>
            </div>
          </vx-card>
        </div>
        <div style="height: 10px"></div>
      </vs-col>

      <vs-col
        vs-justify="center"
        vs-align="center"
        vs-lg="7"
        :vs-xs="'12'"
        :vs-sm="'12'"
      >
        <div v-if="versionSelected">
          <div class="mb-5" style="height: 30px">
            <h5 class="mt-4 float-left">
              <vs-chip class="no-select" color="primary">
                {{ selected.name }}
              </vs-chip>
            </h5>
            <h5 class="mt-4 float-left" v-if="versionSelected.tag !== 'N/A'">
              <vs-chip class="no-select" color>
                {{ versionSelected.tag }}
              </vs-chip>
            </h5>
            <h5 class="mt-4 float-left">
              <vs-chip
                class="no-select"
                :color="`${versionSelected.active ? 'success' : ''}`"
              >
                {{ lang.surveyMaker[versionSelected.status][languageSelected] }}
              </vs-chip>
            </h5>
          </div>
          <survey-editor
            class="mt-4"
            :version="versionSelected._id"
            :surveyId="selected._id"
          ></survey-editor>
        </div>
      </vs-col>
    </div>

    <div v-if="tryIt && selected && versionSelected" class="test-chat">
      <vs-button
        v-if="!iframeLoading"
        class="mt-2 close"
        radius
        icon-pack="feather"
        icon="icon-x"
        type="light"
        @click="tryIt = false"
      ></vs-button>
      <SurveyTest @load="stopLoading" id="iframe" :name="selected.name" />
      <!-- <iframe
          @load="stopLoading"
          id="iframe"
          width="430px"
          height="100%"
          frameborder="0"
          :src="
            `${store.state.config.chat}/#/pages/survey-test?survey=${selected._id}&version=${versionSelected._id}&name=${selected.name}`
          "
        ></iframe> -->
    </div>

    <vs-popup
      classContent="popup-example"
      :title="lang.surveyMaker.surveys.addSurveyPopup.title[languageSelected]"
      :active.sync="newSurvey.popup"
    >
      <section>
        <div class="vx-row">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <vs-input
              :label="
                lang.surveyMaker.surveys.addSurveyPopup.formWizzard.name.label[
                  languageSelected
                ]
              "
              class="mb-5 w-full"
              :placeholder="
                lang.surveyMaker.surveys.addSurveyPopup.formWizzard.name
                  .placeholder[languageSelected]
              "
              v-model="newSurvey.name"
            />
            <div class="vx-row">
              <vs-col vs-justify="center" vs-align="center" vs-w="8">
                <vs-input
                  :label="
                    lang.surveyMaker.surveys.addSurveyPopup.formWizzard.version
                      .label[languageSelected]
                  "
                  class="mb-6 w-full"
                  :placeholder="
                    newSurvey.copy
                      ? 'N/A'
                      : lang.surveyMaker.versions.addPopup.formWizzard.settings
                          .tag.placeholder[languageSelected]
                  "
                  v-model="newSurvey.tag"
                  :disabled="newSurvey.copy"
                />
              </vs-col>
              <vs-col vs-justify="center" vs-align="center" vs-w="4">
                <vs-input
                  :label="
                    lang.surveyMaker.surveys.addSurveyPopup.formWizzard
                      .cantSurvey.label[languageSelected]
                  "
                  class="mb-6 w-full"
                  v-model="newSurvey.cantSurvey"
                  type="number"
                  :min="0"
                />
              </vs-col>
            </div>
            <vs-checkbox class="mb-2" v-model="newSurvey.copy"
              >{{
                lang.surveyMaker.versions.addPopup.formWizzard.fork.title[
                  languageSelected
                ]
              }}&nbsp;</vs-checkbox
            >
            <div v-if="newSurvey.copy" class="mb-base">
              <span
                class="mb-0"
                style="padding-left: 5px; font-size: 12px; color: grey"
              >
                {{
                  lang.surveyMaker.versions.addPopup.formWizzard.fork.addSurvey
                    .label[languageSelected]
                }}&nbsp;
              </span>
              <vs-table
                class="mt-0"
                v-model="newSurvey.copySelected"
                pagination
                max-items="3"
                sorted
                @selected="handleSelectedAddSurvey"
                :data="surveys"
                search
                :no-data-text="lang.general.noDataText[languageSelected]"
              >
                <template slot="thead">
                  <vs-th sort-key="name">
                    {{ lang.surveyMaker.surveys.table.name[languageSelected] }}
                  </vs-th>
                  <vs-th sort-key="_createdAt">
                    {{
                      lang.surveyMaker.surveys.table._createdAt[
                        languageSelected
                      ]
                    }}
                  </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td :data="data[indextr].name">
                      {{ data[indextr].name }}
                    </vs-td>

                    <vs-td :data="data[indextr]._createdAt">
                      {{ new Date(data[indextr]._createdAt) | date(true) }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-col>
        </div>

        <div class="actions">
          <vs-button class="float-right mb-3" @click="saveNewSurvey">
            {{
              lang.surveyMaker.surveys.addSurveyPopup.formWizzard
                .finishButtonText[languageSelected]
            }}
          </vs-button>
          <vs-button
            type="flat"
            class="float-right mr-4 mb-3"
            icon-pack="feather"
            color="danger"
            @click="resetSurvey"
          >
            {{ lang.botMaker.cancel[languageSelected] }}
          </vs-button>
        </div>
      </section>
    </vs-popup>

    <vs-popup
      classContent="popup-example"
      :title="lang.surveyMaker.versions.addPopup.title[languageSelected]"
      :active.sync="newVersion.popup"
    >
      <form-wizard
        ref="newVersionFormWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="
          lang.surveyMaker.versions.addPopup.formWizzard.title[languageSelected]
        "
        :subtitle="
          lang.surveyMaker.versions.addPopup.formWizzard.subtitle[
            languageSelected
          ]
        "
        :nextButtonText="
          lang.surveyMaker.versions.addPopup.formWizzard.nextButtonText[
            languageSelected
          ]
        "
        :backButtonText="
          lang.surveyMaker.versions.addPopup.formWizzard.backButtonText[
            languageSelected
          ]
        "
        :finishButtonText="
          lang.surveyMaker.versions.addPopup.formWizzard.finishButtonText[
            languageSelected
          ]
        "
        @on-complete="saveNewVersion"
      >
        <tab-content
          class="mb-5"
          :title="
            lang.surveyMaker.versions.addPopup.formWizzard.fork.title[
              languageSelected
            ]
          "
          icon="feather icon-git-branch"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <span class="mb-0" style="font-size: 12px; color: grey">
                {{
                  lang.surveyMaker.versions.addPopup.formWizzard.fork.table
                    .label[languageSelected]
                }}&nbsp;
              </span>
              <vs-table
                class="mt-4"
                v-model="newVersion.versionSelected"
                pagination
                max-items="5"
                sorted
                :data="versions"
                :no-data-text="lang.general.noDataText[languageSelected]"
              >
                <template slot="thead">
                  <vs-th>
                    {{ lang.surveyMaker.versions.table.tag[languageSelected] }}
                  </vs-th>
                  <vs-th sort-key="status">
                    {{
                      lang.surveyMaker.versions.table.status[languageSelected]
                    }}
                  </vs-th>
                  <vs-th sort-key="_createdAt">
                    {{
                      lang.surveyMaker.versions.table._createdAt[
                        languageSelected
                      ]
                    }}
                  </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td :data="data[indextr].tag">
                      {{ data[indextr].tag }}
                    </vs-td>

                    <vs-td :data="data[indextr].status">
                      <vs-chip
                        :color="
                          data[indextr].status === 'production' ? 'success' : ''
                        "
                      >
                        {{
                          lang.surveyMaker[data[indextr].status][
                            languageSelected
                          ]
                        }}
                      </vs-chip>
                    </vs-td>
                    <vs-td :data="data[indextr]._createdAt">
                      {{ new Date(data[indextr]._createdAt) | date(true) }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-divider />
              <div v-if="newVersion.versionSelected">
                <vs-chip
                  color="primary"
                  closable
                  @click="newVersion.versionSelected = null"
                >
                  {{
                    `${newVersion.versionSelected.tag} (${lang.surveyMaker[newVersion.versionSelected.status][languageSelected]})`
                  }}
                </vs-chip>
              </div>
            </vs-col>
          </div>
        </tab-content>

        <tab-content
          class="mb-5"
          :title="
            lang.surveyMaker.versions.addPopup.formWizzard.settings.title[
              languageSelected
            ]
          "
          icon="feather icon-settings"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                :label="
                  lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                    .label[languageSelected]
                "
                class="mb-6 w-full"
                :placeholder="
                  lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                    .placeholder[languageSelected]
                "
                v-model="newVersion.tag"
              />
            </vs-col>
          </div>
          <vs-divider />
          <div class="vx-row">
            <vs-row>
              <vs-col class="w-full mb-2">
                <span class="mb-4" style="font-size: 12px; color: grey">
                  &nbsp;{{
                    lang.surveyMaker.versions.addPopup.formWizzard.settings
                      .status.label[languageSelected]
                  }}
                </span>
              </vs-col>
            </vs-row>
            <vs-row class="mb-0">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-xs="6"
                vs-sm="6"
                vs-lg="6"
              >
                <vs-card
                  :class="{ 'active-option': newVersion.production }"
                  class="center no-select"
                  calss="bg--primary-gradient"
                  style="cursor: pointer"
                >
                  <div @click="toggleNewVersionProduction(true)">
                    <h3 :class="{ 'active-title': newVersion.production }">
                      {{ lang.surveyMaker['production'][languageSelected] }}
                    </h3>
                    <br />
                    <feather-icon
                      color="success"
                      icon="CheckCircleIcon"
                      svgClasses="w-8 h-8"
                    ></feather-icon>
                  </div>
                </vs-card>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-xs="6"
                vs-sm="6"
                vs-lg="6"
              >
                <vs-card
                  :class="{ 'active-option': !newVersion.production }"
                  class="center no-select"
                  calss="bg--primary-gradient"
                  style="cursor: pointer"
                >
                  <div @click="toggleNewVersionProduction(false)">
                    <h3 :class="{ 'active-title': !newVersion.production }">
                      {{ lang.surveyMaker['development'][languageSelected] }}
                    </h3>
                    <br />
                    <feather-icon
                      color="success"
                      icon="Edit3Icon"
                      svgClasses="w-8 h-8"
                    ></feather-icon>
                  </div>
                </vs-card>
              </vs-col>
            </vs-row>
            <vs-row v-if="newVersion.production">
              <vs-col class="w-full mb-2">
                <span class="mb-4 text-danger" style="font-size: 12px">
                  {{
                    lang.surveyMaker.versions.addPopup.formWizzard.settings
                      .status.alert[languageSelected]
                  }}
                </span>
              </vs-col>
            </vs-row>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>

    <vs-popup
      classContent="popup-example"
      :title="lang.surveyMaker.versions.editPopup.title[languageSelected]"
      :active.sync="editingVersion.popup"
    >
      <div class="vx-row">
        <vs-col vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            :label="
              lang.surveyMaker.versions.addPopup.formWizzard.settings.tag.label[
                languageSelected
              ]
            "
            class="mb-5 w-full"
            :placeholder="
              lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                .placeholder[languageSelected]
            "
            v-model="editingVersion.tag"
          />
          <vs-input
            :label="
              lang.surveyMaker.surveys.addSurveyPopup.formWizzard.cantSurvey
                .label[languageSelected]
            "
            class="mb-5"
            v-model="editingVersion.cantSurvey"
            type="number"
            :min="0"
          />
        </vs-col>
      </div>
      <vs-divider />
      <div class="vx-row">
        <vs-row>
          <vs-col class="w-full mb-2">
            <span class="mb-4" style="font-size: 12px; color: grey">
              &nbsp;{{
                lang.surveyMaker.versions.addPopup.formWizzard.settings.status
                  .label[languageSelected]
              }}
            </span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-0">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-xs="6"
            vs-sm="6"
            vs-lg="6"
          >
            <vs-card
              :class="{ 'active-option': editingVersion.production }"
              class="center no-select"
              calss="bg--primary-gradient"
              style="cursor: pointer"
            >
              <div @click="toggleEditVersionProduction(true)">
                <h3 :class="{ 'active-title': editingVersion.production }">
                  {{ lang.surveyMaker['production'][languageSelected] }}
                </h3>
                <br />
                <feather-icon
                  color="success"
                  icon="CheckCircleIcon"
                  svgClasses="w-8 h-8"
                ></feather-icon>
              </div>
            </vs-card>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-xs="6"
            vs-sm="6"
            vs-lg="6"
          >
            <vs-card
              :class="{ 'active-option': !editingVersion.production }"
              class="center no-select"
              calss="bg--primary-gradient"
              style="cursor: pointer"
            >
              <div @click="toggleEditVersionProduction(false)">
                <h3 :class="{ 'active-title': !editingVersion.production }">
                  {{ lang.surveyMaker['development'][languageSelected] }}
                </h3>
                <br />
                <feather-icon
                  color="success"
                  icon="Edit3Icon"
                  svgClasses="w-8 h-8"
                ></feather-icon>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
        <vs-row v-if="editingVersion.production">
          <vs-col class="w-full mb-2">
            <span class="mb-4 text-danger" style="font-size: 12px">
              {{
                lang.surveyMaker.versions.addPopup.formWizzard.settings.status
                  .alert[languageSelected]
              }}
            </span>
          </vs-col>
        </vs-row>
      </div>
      <div class="vx-row">
        <vs-row>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
          >
            <vs-button @click="cancelEditVersion" color="danger" type="flat">
              {{
                lang.surveyMaker.versions.editPopup.form.cancel[
                  languageSelected
                ]
              }}
            </vs-button>
            <vs-button @click="saveEditVersion">
              {{
                lang.surveyMaker.versions.editPopup.form.save[languageSelected]
              }}
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
    <delete-survey-prompt
      v-if="deleteSurveyPrompt"
      :toggle-prompt="toggleDeleteSurvey"
      :deleteSurvey="deleteSurvey"
      :survey="selected && selected.name"
    />
  </div>
</template>

<script>
import store from '../../../../store/store'
import { mapState, mapMutations } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import DeleteSurveyPrompt from './DeleteSurveyPrompt'
import services from '../services'
import SurveyEditor from './SurveyEditor'
import SurveyTest from './SurveyTest'
import moment from 'moment'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  components: {
    SurveyTest,
    SurveyEditor,
    FormWizard,
    TabContent,
    DeleteSurveyPrompt
  },
  computed: {
    newSurveyCopy() {
      if (!this.newSurvey) {
        return false
      }
      return this.newSurvey.copy === true
    },
    notActiveVersion() {
      if (!this.versions) {
        return true
      }
      return !this.versions.some(element => element.active)
    },
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement'])
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      deleteSurveyPrompt: false,
      store: store,

      breadcrumb: [
        {
          title: '',
          url: '/#/surveys',
          active: true
        }
      ],

      surveys: [],
      selected: null,
      versions: [],
      versionSelected: null,
      tryIt: false,

      newSurvey: {
        popup: false,
        name: '',
        tag: '',
        cantSurvey: 0,
        copy: false,
        copySelected: null,
        copyVersions: []
      },

      newVersion: {
        popup: false,
        tag: '',
        production: false,
        versionSelected: null
      },

      editingVersion: {
        popup: false,
        tag: null,
        production: null,
        versionSelected: null,
        cantSurvey: 0
      },

      iframeLoading: false,

      integrations: []
    }
  },
  watch: {
    newSurveyCopy(newVal) {
      if (newVal === true && this.newSurvey) {
        this.newSurvey.tag = ''
      }
    },
    // tryIt(newVal) {
    //   if (newVal) {
    //     this.iframeLoading = true
    //     setTimeout(() => {
    //       if (this.iframeLoading) {
    //         this.$vs.loading()
    //       }
    //     }, 1000)
    //   }
    // },
    versions(newVal) {
      if (!newVal || newVal.length === 0) {
        this.newVersion.production = true
      }
    }
  },
  methods: {
    ...mapMutations(['SET_DELETE_SURVEY']),
    toggleDeleteSurvey() {
      this.SET_DELETE_SURVEY(!this.deleteSurveyPrompt)
      this.deleteSurveyPrompt = !this.deleteSurveyPrompt
    },
    async promoteVersion() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.surveyMaker.versions.promotePopup.title[
          this.languageSelected
        ],
        text: (
          this.lang.surveyMaker.versions.promotePopup.text[
            this.languageSelected
          ] || ''
        ).replace(
          'XXX',
          `${this.versionSelected.tag} (${this.versionSelected.status})`
        ),
        accept: async () => {
          try {
            this.$vs.loading()
            const versionSelectedId = this.versionSelected._id
            await services.promoteSurveyVersion(versionSelectedId)
            await this.getVersions(this.selected.service)
            this.versions.some(element => {
              if (element._id === versionSelectedId) {
                this.versionSelected = element
                this.$router.push({
                  query: { survey: this.selected._id, version: element._id }
                })
                this.breadcrumb = [
                  {
                    title: this.lang.surveyData.breadcrumb.surveys[
                      this.languageSelected
                    ],
                    url: '/#/surveys',
                    active: false,
                    disabled: true
                  },
                  {
                    title: this.selected.name,
                    active: false,
                    disabled: true
                  },
                  {
                    title: element.tag,
                    active: true
                  }
                ]
                return true
              }
            })
            this.stopLoading()
            this.$vs.notify({
              title: this.lang.surveyMaker.versions.promotePopup.notify.success
                .title[this.languageSelected],
              text: this.lang.surveyMaker.versions.promotePopup.notify.success
                .text[this.languageSelected],
              color: 'success',
              position: 'top-right'
            })
          } catch (error) {
            console.error(error)
            this.stopLoading()
            this.$vs.notify({
              title: this.lang.surveyMaker.versions.promotePopup.notify.error
                .title[this.languageSelected],
              text: this.lang.surveyMaker.versions.promotePopup.notify.error
                .text[this.languageSelected],
              color: 'danger',
              position: 'top-right'
            })
          }
        }
      })
    },
    toggleNewVersionProduction(newVal) {
      this.newVersion.production = newVal
    },
    addVersion() {
      this.newVersion.tag = ''
      this.newVersion.versionSelected = null
      this.$refs.newVersionFormWizard.reset()
      this.newVersion.popup = true
    },
    async editVersion() {
      this.$vs.loading()
      try {
        await this.getVersions(this.selected.service)
        this.editingVersion.tag = this.versionSelected.tag
        this.editingVersion.cantSurvey = this.versionSelected.cantSurvey
        this.editingVersion.production = this.versionSelected.active
        this.editingVersion.popup = true
      } catch (error) {
        this.$vs.notify({
          title: this.lang.surveyMaker.versions.editPopup.notify.getVersions
            .error.title[this.languageSelected],
          text: this.lang.surveyMaker.versions.editPopup.notify.getVersions
            .error.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    cancelEditVersion() {
      this.editingVersion.popup = false
    },
    toggleEditVersionProduction(newVal) {
      this.editingVersion.production = newVal
    },
    async saveEditVersion() {
      this.$vs.loading()
      try {
        await services.editSurveyVersion(
          this.versionSelected._id,
          this.editingVersion
        )
        await this.getVersions(this.selected.service)
        this.$vs.notify({
          title: this.lang.surveyMaker.versions.editPopup.notify.saveVersion
            .success.title[this.languageSelected],
          text: this.lang.surveyMaker.versions.editPopup.notify.saveVersion
            .success.text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.$router.push({
          query: {
            survey: this.selected._id,
            version: this.versionSelected._id
          }
        })
      } catch (error) {
        this.$vs.notify({
          title: this.lang.surveyMaker.versions.editPopup.notify.saveVersion
            .error.title[this.languageSelected],
          text: this.lang.surveyMaker.versions.editPopup.notify.saveVersion
            .error.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.editingVersion.popup = false
        this.$vs.loading.close()
      }
    },
    stopLoading() {
      this.iframeLoading = false
      this.$vs.loading.close()
    },
    async deleteVersion() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.surveyMaker.versions.deleteDialog.title[
          this.languageSelected
        ],
        text: (
          this.lang.surveyMaker.versions.deleteDialog.text[
            this.languageSelected
          ] || ''
        ).replace(
          'XXX',
          `${this.versionSelected.tag} (${this.versionSelected.status})`
        ),
        accept: async () => {
          try {
            this.$vs.loading()
            await services.deleteSurveyVersion(this.versionSelected._id)
            let index = null
            this.versions.some((element, i) => {
              if (element._id === this.versionSelected._id) {
                index = i
                return true
              }
            })
            this.versions.splice(index, 1)
            this.versionSelected = null
            this.$router.push({ query: { survey: this.selected._id } })
            this.breadcrumb = [
              {
                title: this.lang.surveyData.breadcrumb.surveys[
                  this.languageSelected
                ],
                url: '/#/surveys',
                active: false,
                disabled: true
              },
              {
                title: this.selected.name,
                active: true
              }
            ]
            this.stopLoading()
            this.$vs.notify({
              title: this.lang.surveyMaker.versions.deleteDialog.notify.success
                .title[this.languageSelected],
              text: this.lang.surveyMaker.versions.deleteDialog.notify.success
                .text[this.languageSelected],
              color: 'success',
              position: 'top-right'
            })
          } catch (error) {
            this.stopLoading()
            this.$vs.notify({
              title: this.lang.surveyMaker.versions.deleteDialog.notify.error
                .title[this.languageSelected],
              text: this.lang.surveyMaker.versions.deleteDialog.notify.error
                .text[this.languageSelected],
              color: 'danger',
              position: 'top-right'
            })
          }
        }
      })
    },
    async deleteSurvey(deleteMessages) {
      this.$vs.loading()
      try {
        await services.deleteSurvey(this.selected._id, deleteMessages)
        let index = null
        this.surveys.some((element, i) => {
          if (element._id === this.selected._id) {
            index = i
            return true
          }
        })
        this.surveys.splice(index, 1)
        this.selected = null
        this.versionSelected = null
        this.$router.push({ query: {} })
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys',
            active: true
          }
        ]
        this.stopLoading()
        this.$vs.notify({
          title: this.lang.surveyMaker.surveys.deleteSurveyDialog.notify.success
            .title[this.languageSelected],
          text: this.lang.surveyMaker.surveys.deleteSurveyDialog.notify.success
            .text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
      } catch (error) {
        this.stopLoading()
        this.$vs.notify({
          title: this.lang.surveyMaker.surveys.deleteSurveyDialog.notify.error
            .title[this.languageSelected],
          text: this.lang.surveyMaker.surveys.deleteSurveyDialog.notify.error
            .text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },

    async saveNewVersion(skipLoading) {
      if (!skipLoading) {
        this.$vs.loading()
      }
      try {
        let fork = null
        if (this.newVersion.versionSelected) {
          fork = {
            version: this.newVersion.versionSelected._id
          }
        }
        const result = await services.postSurveyVersion(
          this.selected.service,
          this.newSurvey.tag || this.newVersion.tag,
          this.newVersion.production,
          fork,
          this.newSurvey.cantSurvey
        )
        if (result.data.active) {
          this.versions.forEach(element => {
            element.active = false
            element.status = 'development'
          })
        }
        const newItem = {
          _id: result.data._id,
          active: result.data.active,
          tag: result.data.tag,
          cantSurvey: result.data.cantSurvey,
          status: result.data.active ? 'production' : 'development',
          _createdAt: result.data._createdAt
        }
        if (!skipLoading) {
          this.stopLoading()
        }
        this.versions = [newItem].concat(this.versions)
        this.versionSelected = this.versions[0]
        this.$router.push({
          query: {
            survey: this.selected._id,
            version: this.versionSelected._id
          }
        })
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys',
            active: false,
            disabled: true
          },
          {
            title: this.selected.name,
            active: false,
            disabled: true
          },
          {
            title: this.versionSelected.tag,
            active: true
          }
        ]
        this.newVersion.tag = ''
        this.newVersion.cantSurvey = 0
        this.newVersion.production = false
        this.newVersion.popup = false
        this.$refs.newVersionFormWizard.reset()
        this.$vs.notify({
          title: this.lang.surveyMaker.versions.addPopup.notify.success.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.versions.addPopup.notify.success.text[
            this.languageSelected
          ],
          color: 'success',
          position: 'top-right'
        })
      } catch (error) {
        if (skipLoading !== true) {
          this.stopLoading()
        }
        this.$vs.notify({
          title: this.lang.surveyMaker.versions.addPopup.notify.error.title[
            this.languageSelected
          ],
          text: this.lang.surveyMaker.versions.addPopup.notify.error.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      }
      this.newVersion.popup = false
    },
    resetSurvey() {
      this.newSurvey = {
        popup: false,
        name: '',
        tag: '',
        cantSurvey: 0
      }
    },
    async saveNewSurvey() {
      if (!this.newSurvey.name || this.newSurvey.name.trim().length === 0) {
        this.$vs.notify({
          title: this.lang.surveyMaker.surveys.addSurveyPopup.notify
            .validationFail.title[this.languageSelected],
          text: this.lang.surveyMaker.surveys.addSurveyPopup.notify
            .validationFail.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
        return
      }
      this.$vs.loading()
      try {
        const result = await services.postSurvey(this.newSurvey.name.trim())
        const newItem = {
          _id: result.data._id,
          service: result.data.service,
          name: this.newSurvey.name.trim(),
          _createdAt: result.data._createdAt
        }
        this.surveys = [newItem].concat(this.surveys)
        this.selected = this.surveys[0]
        this.versions = []
        this.versionSelected = null
        this.$router.push({ query: { survey: this.selected._id } })
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys',
            active: false,
            disabled: true
          },
          {
            title: this.selected.name,
            active: true
          }
        ]
        if (
          this.newSurvey.copy &&
          this.newSurvey.copyVersions &&
          this.newSurvey.copyVersions.length > 0
        ) {
          const tasks = []
          for (let i = 0; i < this.newSurvey.copyVersions.length; i += 1) {
            const p = () =>
              new Promise((resolve, reject) => {
                services
                  .postSurveyVersion(
                    this.selected.service,
                    this.newSurvey.copyVersions[i].tag,
                    this.newSurvey.copyVersions[i].active ||
                      this.newSurvey.copyVersions.length === 1,
                    {
                      version: this.newSurvey.copyVersions[i]._id
                    },
                    this.newSurvey.cantSurvey
                  )
                  .then(result => {
                    const newItem = {
                      _id: result.data._id,
                      active: result.data.active,
                      tag: result.data.tag,
                      cantSurvey: result.data.cantSurvey,
                      status: result.data.active ? 'production' : 'development',
                      _createdAt: result.data._createdAt
                    }
                    return resolve(newItem)
                  })
                  .catch(err => reject(err))
              })
            tasks.push(p)
          }
          this.versions = await Promise.all(tasks.map(p => p()))
          // this.versions = [newItem].concat(this.versions)
          this.versionSelected = this.versions[0]
        } else {
          this.newVersion.production = true
          await this.saveNewVersion(true)
        }
        this.stopLoading()
        this.resetSurvey()
        this.$vs.notify({
          title: this.lang.surveyMaker.surveys.addSurveyPopup.notify.success
            .title[this.languageSelected],
          text: this.lang.surveyMaker.surveys.addSurveyPopup.notify.success
            .text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
      } catch (error) {
        this.stopLoading()
        this.$vs.notify({
          title: this.lang.surveyMaker.surveys.addSurveyPopup.notify.error
            .title[this.languageSelected],
          text: this.lang.surveyMaker.surveys.addSurveyPopup.notify.error.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async handleSelectedAddSurvey(tr) {
      this.tryIt = false
      this.$vs.loading()
      const result = await services.getVersions(tr.service)
      this.stopLoading()
      this.newSurvey.copyVersions = result.data
    },
    async handleSelected(tr) {
      this.tryIt = false
      this.$vs.loading()
      await this.getVersions(tr.service)
      this.versionSelected = null
      this.stopLoading()
      this.$router.push({ query: { survey: tr._id } })
      this.breadcrumb = [
        {
          title: this.lang.surveyData.breadcrumb.surveys[this.languageSelected],
          url: '/#/surveys',
          active: false,
          disabled: true
        },
        {
          title: tr.name,
          active: true
        }
      ]
      if (this.versions.length > 0) {
        this.versionSelected = this.versions[0]
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys',
            active: false,
            disabled: true
          },
          {
            title: this.selected.name,
            active: false,
            disabled: true
          },
          {
            title: this.versionSelected.tag,
            active: true
          }
        ]
        this.$router.push({
          query: { survey: this.selected._id, version: this.versions[0]._id }
        })
      }
    },
    async handleSelectedVersion(tr) {
      this.tryIt = false
      this.$router.push({
        query: { survey: this.selected._id, version: tr._id }
      })
      this.breadcrumb = [
        {
          title: this.lang.surveyData.breadcrumb.surveys[this.languageSelected],
          url: '/#/surveys',
          active: false,
          disabled: true
        },
        {
          title: this.selected.name,
          active: false,
          disabled: true
        },
        {
          title: tr.tag,
          active: true
        }
      ]
    },
    async getSurveys() {
      // TODO: PAGINADO.
      try {
        const result = await services.getSurveys()
        const surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
        surveys.sort((a, b) => {
          if (a._createdAt && b._createdAt) {
            const aDate = moment(a._createdAt)
            const bDate = moment(b._createdAt)
            return aDate.isAfter(bDate) ? -1 : 1
          } else if (a._createdAt) {
            // Is After for existing
            return -1
          } else {
            return 1
          }
        })
        this.surveys = surveys
      } catch (error) {
        // TODO: MOSTRAR UN ERROR AL USUARIO.
        console.error('error', error)
      }
    },
    async getVersions(service) {
      // TODO: PAGINADO.
      try {
        const result = await services.getVersions(service)
        this.versions = result.data.map(element => ({
          _id: element._id,
          active: element.active,
          tag: element.tag || 'N/A',
          cantSurvey: element.cantSurvey,
          status: element.active ? 'production' : 'development',
          _createdAt: element._createdAt
        }))
      } catch (error) {
        // TODO: MOSTRAR UN ERROR AL USUARIO.
        console.error('error', error)
      }
    }
  },
  async created() {
    this.$vs.loading()
    await this.getSurveys()
    if (this.$route.query.survey) {
      let i = null
      this.surveys.some((survey, index) => {
        if (survey._id.toString() === this.$route.query.survey) {
          i = index
          return true
        }
      })
      if (i !== null) {
        this.selected = this.surveys[i]
        await this.getVersions(this.selected.service.toString())
        if (this.$route.query.version) {
          let i = null
          this.versions.some((version, index) => {
            if (version._id.toString() === this.$route.query.version) {
              i = index
              return true
            }
          })
          if (i !== null) {
            this.versionSelected = this.versions[i]
            this.breadcrumb = [
              {
                title: this.lang.surveyData.breadcrumb.surveys[
                  this.languageSelected
                ],
                url: '/#/surveys',
                active: false,
                disabled: true
              },
              {
                title: this.selected.name,
                active: false,
                disabled: true
              },
              {
                title: this.versionSelected.tag,
                active: true
              }
            ]
          } else {
            this.breadcrumb = [
              {
                title: this.lang.surveyData.breadcrumb.surveys[
                  this.languageSelected
                ],
                url: '/#/surveys',
                active: false,
                disabled: true
              },
              {
                title: this.selected.name,
                active: true
              }
            ]
          }
        } else if (this.versions.length > 0) {
          this.versionSelected = this.versions[0]
          this.breadcrumb = [
            {
              title: this.lang.surveyData.breadcrumb.surveys[
                this.languageSelected
              ],
              url: '/#/surveys',
              active: false,
              disabled: true
            },
            {
              title: this.selected.name,
              active: false,
              disabled: true
            },
            {
              title: this.versionSelected.tag,
              active: true
            }
          ]
        } else {
          this.breadcrumb = [
            {
              title: this.lang.surveyData.breadcrumb.surveys[
                this.languageSelected
              ],
              url: '/#/surveys',
              active: false,
              disabled: true
            },
            {
              title: this.selected.name,
              active: true
            }
          ]
        }
      }
    }
    this.stopLoading()
  }
}
</script>

<style lang="scss">
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.subtitle {
  margin-top: 20px;
}
.center {
  text-align: center;
}
.bot-icon-large .feather,
img.bot-icon-large {
  height: 160px !important;
  width: 160px !important;
  max-width: 100% !important;
}

.bot-icon-large .feather:hover {
  transform: scale(1.1);
}
</style>

<style lang="scss" scoped>
.block {
  display: block;
  width: 100%;
}
.active-option {
  background: #ff9f38 !important;
  color: white;
}
.active-title {
  color: white;
}

.right {
  text-align: right;
}

.pa-30 {
  padding: 30px;
}

.zap-button {
  width: 200px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  // left: 0px;
  z-index: 999999;
  /* background-image: linear-gradient(to right, transparent, transparent ); */
}

.test-chat {
  position: fixed;
  top: 107px;
  right: 30px;
  z-index: 9000;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 130px);
  background: white;

  .close {
    position: absolute;
    right: 0.7em;
    top: 0.5em;
    color: black;
  }
}
</style>
