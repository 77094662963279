<template>
  <div id="delete-survey-prompt">
    <vs-prompt
      :vs-title="
        lang.surveyMaker.surveys.deleteSurveyDialog.title[languageSelected]
      "
      :vs-active.sync="isPromptActive"
      :vs-accept-text="lang.botMaker.accept[languageSelected]"
      :vs-cancel-text="lang.botMaker.cancel[languageSelected]"
      vs-button-cancel="filled"
      @vs-accept="deleteSurveyAction"
      @vs-close="cancelDelete"
      @vs-cancel="cancelDelete"
    >
      <vs-row>
        <vs-col vs-w="20">
          {{ confirmationText }}
        </vs-col>
        <br />
        <vs-col vs-w="20">
          <vs-checkbox
            v-model="deleteAllMessages"
            v-tooltip="
              lang.surveyMaker.surveys.deleteSurveyDialog
                .deleteMessagesTextAlert[languageSelected]
            "
          >
            {{
              lang.surveyMaker.surveys.deleteSurveyDialog.deleteMessagesText[
                languageSelected
              ]
            }}
          </vs-checkbox>
        </vs-col>
      </vs-row>
    </vs-prompt>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DeleteSurveyPrompt',
  props: {
    togglePrompt: {
      type: Function,
      required: true
    },
    survey: {
      type: String,
      default: ''
    },
    deleteSurvey: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      deleteAllMessages: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'isDeleteSurveyPromptOpen']),
    isPromptActive: {
      get() {
        return this.$store.state.isDeleteSurveyPromptOpen
      },
      set() {/* set */}
    },
    confirmationText: {
      get() {
        return (
          this.lang.surveyMaker.surveys.deleteSurveyDialog.text[
            this.languageSelected
          ] || ''
        ).replace('XXX', this.survey)
      }
    }
  },
  methods: {
    ...mapMutations(['SET_DELETE_SURVEY']),
    async deleteSurveyAction() {
      try {
        await this.deleteSurvey(this.deleteAllMessages)
        this.togglePrompt()
      } catch (error) {
        // errorHandler(error)
        console.error(error)
      }
      this.$vs.loading.close()
    },
    cancelDelete() {
      this.togglePrompt()
    }
    // TODO: move to utils
  }
}
</script>

<style type="scss">
.delete-survey-prompt {
  width: 2000px;
}
.intent-prompt-input {
  width: 100%;
  margin-bottom: 20px;
}
.intent-examples {
  display: flex;
  align-items: center;
}
.intent-input {
  width: 50%;
  display: flex;
  align-items: center;
}
.add-intent-btn {
  margin-left: 5px;
}
.intent-example {
  display: flex;
  align-items: center;
}
.clear-intent-btn {
  margin-right: 5px;
}
.intent-examples-list {
  max-height: 250px;
  overflow-y: auto;
}
.con-vs-dialog .vs-dialog footer button:not(:first-of-type) {
  background: rgba(var(--vs-danger), 1) !important;
}
.con-vs-dialog .vs-dialog footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}
.con-vs-dialog .vs-dialog footer .vs-button {
  margin-left: 0.5rem;
}
.span-text-validation-danger {
  height: unset !important;
}
</style>
