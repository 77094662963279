var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}],staticClass:"overflow-hidden main-card w-full",attrs:{"title":_vm.cardTitle,"title-color":"rgba(241,56,77)","card-background":"white","headerBackground":"rgba(241,56,77)","collapse-action":false,"remove-card-action":false,"refresh-content-action":false,"fixed-height":true}},[_c('template',{staticStyle:{"width":"100%"},slot:"no-body"},[_c('div',{staticClass:"chat-card-log pt-4"},[_c('VuePerfectScrollbar',{ref:"chatLogPS",staticClass:"scroll-area pt-6 px-6",attrs:{"id":"chatLogPS"}},[_c('ul',{ref:"chatLog"},_vm._l((_vm.messages),function(message,index1){return _c('div',{key:(index1 + "vfor1")},[(message.type === 'user')?_c('div',[(
                  message.input &&
                    message.input.length > 0 &&
                    message.input[0] !== _vm.DO_NOT_SHOW_THIS
                )?_c('li',{staticClass:"flex items-start",class:{ 'flex-row-reverse': true, 'mt-4': index1 }},[_c('avatar',{staticClass:"shadow-md cursor-pointer avatar",class:'ml-5 m-0 flex-shrink-0',attrs:{"size":40,"username":_vm.session.name,"lighten":100}}),_vm._v(" "),_c('div',{staticClass:"\n                    msg\n                    relative\n                    bg-white\n                    shadow-md\n                    py-3\n                    px-4\n                    mb-2\n                    rounded-lg\n                    max-w-md\n                  ",class:{
                    'chat-sent-msg bg-primary-gradient text-white': true
                  }},[_c('span',[_vm._v(_vm._s(message.input[0]))])])],1):_vm._e(),_vm._v(" "),_vm._l((message.output),function(response,index){return _c('div',{key:(index + "vfor2")},[(
                    response.responseText &&
                      response.responseText.trim().length > 0
                  )?_c('li',{staticClass:"flex items-start",class:{
                    'flex-row-reverse': false,
                    'mt-4': index1 != 0
                  }},[_c('avatar',{staticClass:"shadow-md cursor-pointer avatar",class:'mr-5',attrs:{"size":40,"username":_vm.name,"lighten":100}}),_vm._v(" "),_c('div',{staticClass:"\n                      msg\n                      relative\n                      bg-white\n                      shadow-md\n                      py-3\n                      px-4\n                      mb-2\n                      rounded-lg\n                      max-w-md\n                    ",class:{ 'bg-warning-gradient text-white': true }},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.textToHTML(response.responseText)),expression:"textToHTML(response.responseText)"}]})])],1):_vm._e(),_vm._v(" "),(
                    response.responseFile &&
                      response.responseFile.type &&
                      response.responseFile.url
                  )?_c('li',{staticClass:"flex items-start",class:{
                    'flex-row-reverse': false,
                    'mt-4': index1 != 0
                  }},[_c('avatar',{staticClass:"shadow-md cursor-pointer avatar",class:'mr-5',attrs:{"size":40,"username":_vm.name,"lighten":100}}),_vm._v(" "),_c('div',{staticClass:"\n                      msg\n                      relative\n                      bg-white\n                      shadow-md\n                      py-3\n                      px-4\n                      mb-2\n                      rounded-lg\n                      max-w-md\n                    ",class:{ 'bg-warning-gradient text-white': true }},[_c('img',{attrs:{"src":response.responseFile.url,"width":"100%","height":"100%"}})])],1):_vm._e(),_vm._v(" "),(
                    response.responseOptions &&
                      response.responseOptions.length > 0
                  )?_c('div',{staticClass:"flex items-start",staticStyle:{"margin-left":"55px"}},_vm._l((response.responseOptions),function(option,index){return _c('div',{key:(index + "vfor3"),staticClass:"items-start",on:{"click":function($event){_vm.send({
                        text: option.text,
                        skip: _vm.sending || index1 !== _vm.messages.length - 1
                      })}}},[_c('vs-chip',{staticClass:"mt-4 no-select pt-2 pb-2",style:({
                        cursor:
                          _vm.sending || index1 !== _vm.messages.length - 1
                            ? ''
                            : 'pointer',
                        'font-size': '14px'
                      }),attrs:{"color":_vm.sending || index1 !== _vm.messages.length - 1
                          ? ''
                          : 'warning'}},[_vm._v("\n                      "+_vm._s(option.text)+"\n                    ")])],1)})):_vm._e()])})],2):_c('div',[_c('li',{staticClass:"flex items-start",class:{ 'flex-row-reverse': false, 'mt-4': index1 != 0 }},[_c('avatar',{staticClass:"shadow-md cursor-pointer avatar",class:'mr-5',attrs:{"size":40,"username":_vm.name,"lighten":100}}),_vm._v(" "),_c('div',{staticClass:"\n                    msg\n                    relative\n                    bg-white\n                    shadow-md\n                    py-3\n                    px-4\n                    mb-2\n                    rounded-lg\n                    max-w-md\n                  ",class:{ 'bg-warning-gradient text-white': true }},[_c('span',[_vm._v(_vm._s(message.input[0]))])])],1)])])}))])],1),_vm._v(" "),_c('div',{staticClass:"flex chat-input-container p-6"},[_c('vs-input',{directives:[{name:"focus",rawName:"v-focus"}],ref:"input",staticClass:"mr-3 w-full",attrs:{"color":"rgba(241,56,77)","placeholder":_vm.inputPlaceholder,"disabled":_vm.sending},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send($event)}},model:{value:(_vm.chatMsgInput),callback:function ($$v) {_vm.chatMsgInput=$$v},expression:"chatMsgInput"}}),_vm._v(" "),_c('vs-button',{attrs:{"color":"rgba(241,56,77)","radius":"","type":"filled","icon-pack":"feather","icon":"icon-send","disabled":!_vm.chatMsgInput || _vm.chatMsgInput.trim().length === 0 || _vm.sending},on:{"click":_vm.send}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }